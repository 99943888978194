import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import GlobalContext from "./context/GlobalContext";
function Sidebar() {
  const { permissible_roles } = useContext(GlobalContext);
  const [cookies] = useCookies(["permissions"]);

  const [isActive, setIsActive] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Extract the current route from the location object
    const currentRoute = location.pathname;

    // Determine the active item based on the current route
    setActiveItem(currentRoute);
  }, [location]);

  const handleMenuClick = (itemName) => {
    setActiveItem(itemName);
  };

  return (
    <>
      <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav" style={{ marginTop: "50px" }}>
          {permissible_roles.includes("dashboard") ? (
            <>
              <li className={`nav-item ${activeItem === "/" ? "active" : ""}`}>
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => handleMenuClick("/")}
                >
                  <i className="mdi mdi-grid-large menu-icon"></i>
                  <span className="menu-title">Dashboard</span>
                </Link>
              </li>
            </>
          ) : (
            <></>
          )}
          {permissible_roles.includes("hubs_dist") ? (
            <>
              <li
                className={`nav-item ${
                  activeItem === "/vendors_data" ? "active" : ""
                }`}
              >
                <Link
                  className="nav-link"
                  to="/vendors_data"
                  onClick={() => handleMenuClick("/vendors_data")}
                >
                  <i className="menu-icon mdi mdi-account-multiple"></i>
                  <span className="menu-title">Hubs/Distributors</span>
                </Link>
              </li>
            </>
          ) : (
            <></>
          )}

          {permissible_roles.includes("locations") ? (
            <>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="collapse"
                  href="#locations"
                  aria-expanded="false"
                  aria-controls="locations"
                >
                  <i class="menu-icon mdi mdi-map-marker"></i>
                  <span class="menu-title">Locations</span>
                  <i class="menu-arrow"></i>
                </a>
                <div class="collapse" id="locations">
                  <ul class="nav flex-column sub-menu">
                    {permissible_roles.includes("locations") ? (
                      <>
                        <li
                          className={`nav-item ${
                            activeItem === "/location" ? "active" : ""
                          }`}
                        >
                          {" "}
                          <Link
                            class="nav-link"
                            to="/location"
                            onClick={() => handleMenuClick("/location")}
                          >
                            Locations
                          </Link>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                    {permissible_roles.includes("routes") ? (
                      <>
                        <li class="nav-item">
                          {" "}
                          <Link class="nav-link" to="/routes">
                            Routes
                          </Link>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </li>
            </>
          ) : (
            <></>
          )}

          {permissible_roles.includes("customers") ? (
            <>
              <li
                className={`nav-item ${
                  activeItem === "/customers" ? "active" : ""
                }`}
              >
                <Link
                  class="nav-link"
                  to="/customers"
                  onClick={() => handleMenuClick("/customers")}
                >
                  <i class="menu-icon mdi mdi-file-document"></i>
                  <span class="menu-title">Customers</span>
                </Link>
              </li>
            </>
          ) : (
            <></>
          )}

          {permissible_roles.includes("marketing") ? (
            <>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="collapse"
                  href="#marketing"
                  aria-expanded="false"
                  aria-controls="marketing"
                >
                  <i class="menu-icon mdi mdi-account-convert"></i>
                  <span class="menu-title">Marketing</span>
                  <i class="menu-arrow"></i>
                </a>
                <div class="collapse" id="marketing">
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item">
                      {" "}
                      <Link class="nav-link" to="/banners">
                        Banners
                      </Link>
                    </li>
                    <li class="nav-item">
                      {" "}
                      <Link class="nav-link" to="/marketing">
                        Marketing
                      </Link>
                    </li>
                    <li class="nav-item">
                      {" "}
                      <Link class="nav-link" to="/cart-products">
                        Cart Products
                      </Link>
                    </li>
                    <li class="nav-item">
                      {" "}
                      <Link class="nav-link" to="/recurring-customers">
                        Recurring Customers
                      </Link>
                    </li>
                    <li class="nav-item">
                      {" "}
                      <Link class="nav-link" to="/communication">
                      Communication
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </>
          ) : (
            <></>
          )}

          {permissible_roles.includes("reports") ? (
            <>
              <li
                className={`nav-item ${
                  activeItem === "/reports" ? "active" : ""
                }`}
              >
                <Link
                  class="nav-link"
                  to="/reports"
                  onClick={() => handleMenuClick("/reports")}
                >
                  <i class="menu-icon mdi mdi-amplifier"></i>
                  <span class="menu-title">Reports</span>
                </Link>
              </li>
            </>
          ) : (
            <></>
          )}

          {permissible_roles.includes("products") ? (
            <>
              <li
                className={`nav-item ${
                  activeItem === "/products" ? "active" : ""
                }`}
              >
                <Link
                  class="nav-link"
                  to="/products"
                  onClick={() => handleMenuClick("/products")}
                >
                  <i class="menu-icon mdi mdi-amplifier"></i>
                  <span class="menu-title">Products</span>
                </Link>
              </li>
            </>
          ) : (
            <></>
          )}

          {permissible_roles.includes("tickets") ? (
            <>
              <li
                className={`nav-item ${
                  activeItem === "/tickets" ? "active" : ""
                }`}
              >
                <Link
                  class="nav-link"
                  to="/tickets"
                  onClick={() => handleMenuClick("/tickets")}
                >
                  <i class="menu-icon mdi mdi-amplifier"></i>
                  <span class="menu-title">Tickets</span>
                </Link>
              </li>
            </>
          ) : (
            <></>
          )}

          {permissible_roles.includes("b2b") ? (
            <>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="collapse"
                  href="#b2b"
                  aria-expanded="false"
                  aria-controls="b2b"
                >
                  <i class="menu-icon mdi mdi-amplifier"></i>
                  <span class="menu-title">B2B</span>
                  <i class="menu-arrow"></i>
                </a>
                <div class="collapse" id="b2b">
                  <ul className="nav flex-column sub-menu">
                    {permissible_roles.includes("b2b") && (
                      <>
                        <li
                          className={`nav-item ${
                            activeItem === "/cafe-management" ? "active" : ""
                          }`}
                        >
                          <Link
                            className="nav-link"
                            to="/cafe-management"
                            onClick={() => handleMenuClick("/cafe-management")}
                          >
                            Cafe Management
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/cafe-summary">
                            Summary
                          </Link>
                        </li>
                        <li
                          className={`nav-item ${
                            activeItem === "/b2b_banner" ? "active" : ""
                          }`}
                        >
                          <Link
                            className="nav-link"
                            to="/b2b_banner"
                            onClick={() => handleMenuClick("/b2b_banner")}
                          >
                            B2b Banner
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </li>
            </>
          ) : (
              <></>
          )}
              

          {permissible_roles.includes("inventory") ? (
            <>
              <li
                className={`nav-item ${
                  activeItem === "/inventory-management" ? "active" : ""
                }`}
              >
                <Link
                  class="nav-link"
                  to="/inventory-management"
                  onClick={() => handleMenuClick("/inventory-management")}
                >
                  <i class="menu-icon mdi mdi-amplifier"></i>
                  <span class="menu-title">Inventory</span>
                </Link>
              </li>
            </>
          ) : (
            <></>
          )}
        </ul>
      </nav>
    </>
  );
}

export default Sidebar;
