const WATI_API_ENDPOINT = "https://live-mt-server.wati.io/12352";
const API_KEY = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwODc1ZTczNC1kZTE4LTRlOTAtODVhOC01OGYzYmI1ZTQxMmIiLCJ1bmlxdWVfbmFtZSI6ImluZm9Ad2h5dGVmYXJtcy5jb20iLCJuYW1laWQiOiJpbmZvQHdoeXRlZmFybXMuY29tIiwiZW1haWwiOiJpbmZvQHdoeXRlZmFybXMuY29tIiwiYXV0aF90aW1lIjoiMDIvMjEvMjAyNSAwNDo0ODozNyIsInRlbmFudF9pZCI6IjEyMzUyIiwiZGJfbmFtZSI6Im10LXByb2QtVGVuYW50cyIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkFETUlOSVNUUkFUT1IiLCJleHAiOjI1MzQwMjMwMDgwMCwiaXNzIjoiQ2xhcmVfQUkiLCJhdWQiOiJDbGFyZV9BSSJ9.4BQUdUaut5fxbGfDcLd1PPMptwWmqNCh3bxSx7bZF-Q"; 
const textLocalApiKey = "SsSIU4tDHEo-DWKzpHRKAWG8AtBIe3cMmkksUGCfPH";


export const sendWhatsAppMessage = async (whatsappNumber, templateName, parameters = []) => {
    console.log("parameters :",  parameters);
    const payload = {
        whatsappNumber,  
        template_name: templateName,  
        broadcast_name: "Campaign1",  
        parameters: parameters.map(param => ({ name: param.name, value: param.value }))
    };
  
    try {
        const response = await fetch(`${WATI_API_ENDPOINT}/api/v1/sendTemplateMessage?whatsappNumber=${whatsappNumber}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json-patch+json",
                "Authorization": API_KEY
            },
            body: JSON.stringify(payload)
        });
  
        const data = await response.json();
        console.log("Message sent successfully:", data);
        return data;
    } catch (error) {
        console.error("Error sending WhatsApp message:", error);
        return {customer_phone: whatsappNumber, status : "failed"};
    }
  };
  

export const getMessageTamplats = async () => {
  
    // const payload = {
    //     parameters: parameters.map(param => ({ name: param.name, value: param.value }))
    // };
  
    try {
        const response = await fetch(`${WATI_API_ENDPOINT}/api/v1/getMessageTemplates?pageSize=200`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json-patch+json",
                "Authorization": API_KEY
            },
            // body: JSON.stringify(payload)
        });
  
        const data = await response.json();
        console.log("Message Templets fetch successfully:", data);
        return data.messageTemplates;
    } catch (error) {
        console.error("Error fetching WhatsApp message Templets:", error);
        return null;
    }
  };
  

export const getTextlocalMessageTamplats = async () => {
        const url = `https://api.textlocal.in/get_templates/?apikey=${encodeURIComponent(textLocalApiKey)}`;
        
        try {
            const response = await fetch(url);
            const data = await response.json()// If response is JSON, use response.json()
            console.log("TextLocal Message Templets fetch successfully:", data);
            return { data, status: response.status };
        } catch (error) {
            console.error("Error fetching templates:", error);
            return { error: error.message, status: null };
        }
    }
    