import React, { useEffect, useState } from "react";
import db from "../../Firebase";
import Swal from "sweetalert2";
import { Timestamp } from "firebase/firestore";
import DateInput from "../../components/Date";
const PlaceOrderModal = ({ orderFormData }) => {
  const Toast = Swal.mixin({
    toast: true,
    background: "#69aba6",
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const [errors, setErrors] = useState({});
  const [productList, setProductList] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [quantityOptions, setQuantityOptions] = useState([]);
  const [productPrice, setProductPrice] = useState("");
  const [selectedProductData, setSelectedProductData] = useState(null);
  const [loading, setLoading] = useState(false);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const initialOrderFormData = {
    product_name: "",
    package_unit: "",
    quantity: "",
    delivery_date: tomorrow,
  };
  const [formData, setFormData] = useState({ ...initialOrderFormData });

  const resetForm = () => {
    setFormData({ ...initialOrderFormData });
  };

  const handleClose = () => {
    resetForm();
  };

  const generateOrderId = () => {
    const timestamp = Date.now().toString();
    const randomComponent = Math.floor(Math.random() * 100);
    const uniqueOrderId =
      (timestamp % 100000000).toString().padStart(6, "0") +
      randomComponent.toString().padStart(2, "0");
    return uniqueOrderId;
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const updatedData = {
      ...orderFormData,
      formData,
    };

    const deliveryDate = new Date(updatedData.formData.delivery_date);
    if (isNaN(deliveryDate.getTime()) || deliveryDate.getTime() < 0) {
      setLoading(false);
      Toast.fire({
        icon: "error",
        title: "Invalid delivery date",
      });
      return;
    }

    if (updatedData.type === "E-Commerce") {
      updatedData.cafe_location = "";
    }

    const user = localStorage.getItem("loggedIn_user");
    const userId = localStorage.getItem("userId");

    const orderDetails = {
      order_id: generateOrderId(),
      cafe_id: updatedData.cafe_id,
      created_date: new Date().toISOString().split("T")[0],
      created_at: new Date(),
      created_by: user || "no user",
      Created_by_user_id: userId || null,
      order_date: new Date().toISOString().split("T")[0],
      order_type: updatedData.type,
      package_unit: updatedData.formData.package_unit,
      product_name: updatedData.formData.product_name,
      quantity: Number(updatedData.formData.quantity),
      delivering_to: updatedData.cafe_name,
      delivery_date: new Date(updatedData.formData.delivery_date)
        .toISOString()
        .split("T")[0],
      delivery_timestamp: Timestamp.fromDate(deliveryDate),
      hub_name: updatedData.delivery_hub,
      location: updatedData.cafe_location,
      total_amount: productPrice * Number(updatedData.formData.quantity),
      status: 0,
      updated_at: null,
      updated_by: null,
      updated_date: null,
      cancelled_reason: "",
      cancelled_time: "",
      customer_id: "",
      customer_name: "",
      customer_phone: "",
      delivery_exe_id: "",
      delivery_time: "00:00:00",
      handling_charges: 0,
      marked_delivered_by: "",
      price: productPrice,
      subscription_id: "0",
      tax: 0,
    };

    await db
      .collection("b2b_orders")
      .add(orderDetails)
      .then(() => {
        // console.log("Order added successfully", orderDetails);
        resetForm();
        window.modelhidePlaceOrder();
        setLoading(false);
        Toast.fire({
          icon: "success",
          title: "Order Created",
        });
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error adding document: ", error);
        Toast.fire({
          icon: "error",
          title: "Error creating order",
        });
      });
  };

  const [date, setDate] = useState(null);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      ["delivery_date"]: date,
    }));
  }, [date]);
  const handleChange = async (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    if (id === "product_name") {
      const selectedProduct = productList.find(
        (product) => product.value === value
      );
      if (selectedProduct) {
        const productDoc = await db
          .collection("products_data")
          .doc(selectedProduct.id)
          .get();
        const productData = productDoc.data();
        setSelectedProductData(productData);
        const packageOptions = productData.packagingOptions.map((option) => ({
          value: `${option.packaging} ${option.pkgUnit}`,
          label: `${option.packaging} ${option.pkgUnit}`,
          price: option.price,
        }));
        setQuantityOptions(packageOptions);
      }
    }
    if (id === "package_unit") {
      const selectedOption = quantityOptions.find(
        (option) => option.value === value
      );
      if (selectedOption) {
        const selectedPackaging = selectedProductData.packagingOptions.find(
          (option) => `${option.packaging} ${option.pkgUnit}` === value
        );
        if (selectedPackaging) {
          setProductPrice(selectedPackaging.price);
        }
      }
    }
  };

  const fetchProductList = async () => {
    setProductsLoading(true);
    try {
      const snapshot = await db
        .collection("products_data")
        .where("publishOnB2B", "==", true)
        .get();
      const products = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          value: `${data.productName}`,
          label: `${data.productName}`,
        };
      });
      setProductList(products);
    } catch (error) {
      console.error("Error fetching products: ", error);
    } finally {
      setProductsLoading(false);
    }
  };

  useEffect(() => {
    fetchProductList();
  }, []);

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="">
            <img
              alt="loader"
              style={{
                height: "6rem",
              }}
              src="images/loader.gif"
            ></img>
          </div>
        </div>
      )}
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Place Order
        </h5>
        <button
          type="button"
          class="close"
          aria-label="Close"
          data-bs-dismiss="modal"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="myForm" onSubmit={handleSubmit}>
          <div class="form-group row">
            <div class="flex mb-3">
              <label>
                Product<sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                className={`form-control ${
                  errors.product_name ? "is-invalid" : ""
                }`}
                onChange={handleChange}
                id="product_name"
                value={formData.product_name}
                required
                disabled={productsLoading}
              >
                <option value="">Select Product</option>
                {productList.map((product) => (
                  <option key={product.value} value={product.value}>
                    {product.label}
                  </option>
                ))}
              </select>
            </div>
            <div class="flex flex-row items-center gap-2">
              <div>
                <label>
                  Package Unit<sup style={{ color: "red" }}>*</sup>
                </label>
                <select
                  className={`form-control ${
                    errors.package_unit ? "is-invalid" : ""
                  }`}
                  onChange={handleChange}
                  id="package_unit"
                  value={formData.package_unit}
                  required
                >
                  <option value="">Select Packaging</option>
                  {quantityOptions.map((quantityOptions) => (
                    <option
                      key={quantityOptions.value}
                      value={quantityOptions.value}
                    >
                      {quantityOptions.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="w-full d-flex flex-col gap-2">
            <div class="form-group row">
              <div>
                <label>
                  Quantity<sup style={{ color: "red" }}>*</sup>
                </label>
                <input 
                  style={{
                    padding: "6px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    width: "200px",
                    backgroundColor: "#E9ECEF",
                  }}
                  type="number"
                  onChange={handleChange}
                  id="quantity"
                  value={formData.quantity}
                  required
                  autoComplete="off"
                  placeholder="Enter Quantity"
                  min="1"
                  step="1"
                />
                {errors.quantity && (
                  <div className="invalid-feedback">
                    Please enter a valid positive integer for quantity.
                  </div>
                )}
              </div>
            </div>

            <div class="form-group row">
              <div class="flex flex-row">
                <label>
                  Select Delivery Date<sup style={{ color: "red" }}>*</sup>:{" "}
                </label>
                <div>
                  <DateInput
                    date={date}
                    setDate={setDate}
                    style={{ width: "200px", backgroundColor: "#E9ECEF" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="submit" value="submit" class="btn btn-success">
              Submit
            </button>
            <button
              type="button"
              class="btn btn-light"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PlaceOrderModal;
