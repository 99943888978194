import axios from 'axios';
const textLocalApiKey = "SsSIU4tDHEo-DWKzpHRKAWG8AtBIe3cMmkksUGCfPH";
const Cookeie_code =  'PHPSESSID=rrf18apus13hs3iiu59839u5q4'
function cleanMessage(template, variables) {
    return template.replace(/%%\|([^\^]+)\^\{"inputtype"[^%]+%%/g, (match, key) => {
        return variables[key] !== undefined ? variables[key] : "0";
    });
}

export async function sendSMS(phone_number, balance) {
    const b = -100
    // const message = `Dear Customer, Your account balance is low (Rs. ${b} ). Please recharge with the link https://pmny.in/AIuL89hOjgFN immediately to avoid service disruption - Whyte Farms`;
    
    const message = cleanMessage(balance, new Map());
    console.log(message.length);

    console.log("message: " + message);
    
    const url = `https://api.textlocal.in/send/?apikey=${textLocalApiKey}&sender=WHYTEF&numbers=91${phone_number}&message=${encodeURIComponent(message)}`;
    
    try {
        const response = await axios.get(url);
        console.log(response.data);
        return { data: response.data, status: response.status };
    } catch (error) {
        console.error('Error sending SMS:', error);
        return { error: error.message, status: error.response?.status || null };
    }
}
