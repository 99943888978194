import React, { useContext, useEffect, useMemo, useState } from "react";
import { HubDropdown, ProductDropdown } from "./utility/productDropDown";
import { DateTimeUtil } from "../../Utility";
import { DateInputEnd } from "./Delivery";
import { FaEdit, FaInfoCircle, FaSave, FaTimes, FaTrash } from "react-icons/fa";
import { useInventoryContext } from "./InventoryContext";
import FetchInventory, {
  AddInventory,
  UpdateInventory,
} from "./utility/queries";
import db from "../../Firebase";
import GlobalContext from "../../context/GlobalContext";
import Swal from "sweetalert2";

const Dispatch = () => {
  const exportToCSV = () => {
    // Define headers for the CSV
    const headers = [
      "Dispatch ID",
      "Sub Dispatch ID",
      "Hub",
      "Product",
      "Quantity",
      "Status",
      "Added On",
      "Dispatched On",
      "Delivered On",
      "Created By",
      "Updated By",
      "Accepted By",
    ];
  
    // Format rows
    const rows = dispatches.map((item) => [
      item.dispatch_id || "N/A",
      item.dispatch_sub_id || "N/A",
      item.hub || "N/A",
      item.productName || "N/A",
      item.quantity || "N/A",
      item.status || "N/A",
      item.date
        ? `${DateTimeUtil.timestampFromDBToISODate(item.date)} ${DateTimeUtil.timestampFromDBToTimeAMPM(item.date)}`
        : "N/A",
      item.dispatch_date
        ? `${DateTimeUtil.timestampFromDBToISODate(item.dispatch_date)} ${DateTimeUtil.timestampFromDBToTimeAMPM(item.dispatch_date)}`
        : "N/A",
      item.accept_at
        ? `${DateTimeUtil.timestampFromDBToISODate(item.accept_at)} ${DateTimeUtil.timestampFromDBToTimeAMPM(item.accept_at)}`
        : "N/A",
      item.created_by || "N/A",
      item.user || "N/A",
      item.accept_user || "N/A",
    ]);
  
    // Combine headers and rows into a CSV string
    const csvContent = [
      headers.join(","), // Join headers
      ...rows.map((row) => row.map((value) => `"${value}"`).join(",")), // Quote and join each row
    ].join("\n");
  
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
  
    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "dispatches.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const { dispatches, setDispatches } = useInventoryContext();
  const [create, setCreate] = useState(false);
  const [date, setDate] = useState(new Date());
  const [editingDispatchId, setEditingDispatchId] = useState(null);
  const [updatedDispatches, setUpdatedDispatches] = useState([]);
  const [updatedStatus, setUpdatedStatus] = useState("Added");
  const [isIconLoaded, setIsIconLoaded] = useState(true);
  const [showGuide, setShowGuide] = useState(false); // Guide visibility state
  const inventoryFetch = new FetchInventory();

  const {permissible_roles} = useContext(GlobalContext);
  
    const rolePermission = () => {
      const Toast = Swal.mixin({
        toast: true,
        background: '#d7e7e6',
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
      });
  
      Toast.fire({
        icon: 'error',
        title: 'You are not authorised to do this action'
      });
  
    }

  // Ensure icons are loaded
  useEffect(() => {
    const checkIconLoad = () => {
      try {
        FaSave && FaTimes ? setIsIconLoaded(true) : setIsIconLoaded(false);
      } catch (error) {
        setIsIconLoaded(false);
      }
    };
    checkIconLoad();
  }, []);

  // Fetch dispatches when the selected date changes
  useEffect(() => {
    const fetchDispatchesByDate = async () => {
      try {
        const data = await inventoryFetch.fetchDispatchesByDateRange(date);
        setDispatches(data);
      } catch (error) {
        console.error("Error fetching dispatches:", error);
      }
    };
    fetchDispatchesByDate();
  }, [date]);

  // Fetch all dispatches if the create state is false
  useEffect(() => {
    const fetchDispatches = async () => {
      if (!create) {
        try {
          const data = await inventoryFetch.fetchDispatchesByDateRange(new Date());
          setDispatches(data);
        } catch (error) {
          console.error("Error fetching dispatches:", error);
        }
      }
    };
    fetchDispatches();
  }, [create]);

  // Handle editing a specific dispatch
  const handleUpdate = (dispatchId) => {
    const group = dispatches.filter((item) => item.dispatch_id === dispatchId);
    setUpdatedDispatches(group);
    setUpdatedStatus(group[0]?.status || "Added");
    setEditingDispatchId(dispatchId);
  };

  // Save updates to dispatches
  const saveUpdates = async () => {
    try {
      const update = new UpdateInventory();

      const updatedList = await Promise.all(
        dispatches.map(async (item) => {
          if (item.dispatch_id === editingDispatchId) {
            const matchingDispatch = updatedDispatches.find(
              (d) => d.dispatch_sub_id === item.dispatch_sub_id
            );

            if (matchingDispatch) {
              const updatedData = await update.updateDispatch(
                item.dispatch_sub_id,
                matchingDispatch.quantity,
                updatedStatus,
                localStorage.getItem("loggedIn_user"),
                localStorage.getItem("userId")
              );

              if (updatedData) {
                return { ...item, ...updatedData };
              }
            }
          }
          return item;
        })
      );

      // Reset state after updates
      setEditingDispatchId(null);
      setUpdatedDispatches([]);
      setUpdatedStatus("Added");

      // Refresh dispatches
      const refreshedDispatches = await inventoryFetch.fetchDispatchesByDateRange(date);
      setDispatches(refreshedDispatches);

    } catch (error) {
      console.error("Error saving updates:", error);
    }
  };

  // Handle quantity change for sub-dispatch
  const handleQuantityChange = (subId, quantity) => {
    setUpdatedDispatches((prev) =>
      prev.map((item) =>
        item.dispatch_sub_id === subId
          ? { ...item, quantity: Number(quantity) }
          : item
      )
    );
  };

  // Delete a sub-dispatch
  const deleteSubDispatch = (index) => {
    const updatedDispatches = dispatches.filter((_, i) => i !== index);
    setDispatches(updatedDispatches);
  };
  return (
    <>
      {showGuide && (
        <UserGuideModal showGuide={showGuide} setShowGuide={setShowGuide} />
      )}
  
      {/* Dispatch Overview Section */}
      <div className="card p-1 mb-4">
        <div className="container my-2">
          <div className="d-flex justify-content-between align-items-center mt-1">
            <div className="d-flex flex-column gap-2">
              <h3 className="">Dispatch Management</h3>
              <div className="d-flex align-items-center gap-3">
                <p className="text-black fs-6 mb-0">Date:</p>
                <DateInputEnd
                  date={date}
                  setDate={setDate}
                  style={{ width: "140px", backgroundColor: "#F8F9FA", borderRadius: "0.375rem" }}
                />
              </div>
            </div>

            <div className="d-flex flex-column align-items-end gap-2">
              {/* First row: Export and Info */}
              <div className="d-flex flex-row align-items-center gap-2">
                {/* Export to CSV Button */}
                <button className="btn btn-success btn-rounded btn-sm" onClick={exportToCSV}>
                  Export to CSV
                </button>

                {/* Info Button */}
                <button
                 className="btn btn-sm d-flex justify-content-start align-items-end"
                  onClick={() => setShowGuide(true)}
                  style={{
                    padding: "0px",
                    borderRadius: "50%",
                  }}
                  title="User Guide"
                >
                  <FaInfoCircle size={20} className="text-black" />
                </button>
              </div>

              {/* Second row: Create New Dispatch */}
              {permissible_roles.includes('create_new_dispatch') ? (
                <button className="btn btn-primary btn-rounded btn-sm" onClick={() => setCreate(true)}>
                  Create New Dispatch
                </button>
              ) : (
                <button className="btn btn-primary btn-rounded btn-sm" onClick={() => rolePermission()}>
                  Create New Dispatch
                </button>
              )}
            </div>
          </div>
      </div>
      </div>

  
      {/* Create Dispatch Modal */}
      {create && (
        <CreateDispatch
          setDispatches={setDispatches}
          closeCreate={() => setCreate(false)}
        />
      )}
  
      {/* Dispatches List */}
      <div className="card p-3 mb-4">
        <div className="container my-4">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="text-primary mb-0">List of Dispatches</h4>
          </div>
  
          {/* Dispatches Table */}
          <div className="table-responsive mt-4">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Dispatch ID</th>
                  <th>Sub Dispatch ID</th>
                  <th>Hub</th>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Status</th>
                  <th>Actions</th>
                  <th>Added On</th>
                  <th>Dispatched On</th>
                  <th>Delivered On</th>
                  <th>Created By</th>
                  <th>Updated By</th>
                  <th>Accepted By</th>
                </tr>
              </thead>
              <tbody>
                {dispatches.length > 0 ? (
                  dispatches.map((item, index) => {
                    const isFirstRowOfGroup =
                      index === 0 ||
                      dispatches[index - 1].dispatch_id !== item.dispatch_id;
  
                    const groupRowCount = dispatches.filter(
                      (dispatch) => dispatch.dispatch_id === item.dispatch_id
                    ).length;
  
                    const isEditable = item.status === "Added";
  
                    return (
                      <tr key={`${item.dispatch_id}-${item.dispatch_sub_id}`}>
                        {isFirstRowOfGroup && (
                          <td rowSpan={groupRowCount}>
                            {item.dispatch_id || "N/A"}
                          </td>
                        )}
                        <td>{item.dispatch_sub_id}</td>
                        <td>{item.hub}</td>
                        <td>{item.productName}</td>
                        <td>
                          {editingDispatchId === item.dispatch_id ? (
                            <input
                              type="number"
                              value={
                                updatedDispatches.find(
                                  (d) => d.dispatch_sub_id === item.dispatch_sub_id
                                )?.quantity || item.quantity
                              }
                              onChange={(e) =>
                                handleQuantityChange(
                                  item.dispatch_sub_id,
                                  e.target.value
                                )
                              }
                              min="1"
                              style={{
                                width: `${Math.max(
                                  50,
                                  String(item.quantity.toString()).length * 10
                                )}px`,
                              }}
                            />
                          ) : (
                            item.quantity
                          )}
                        </td>
                        <td
                          className={`fw-bold ${
                            item.status === "Delivered"
                              ? "text-success"
                              : item.status === "Dispatched"
                              ? "text-warning"
                              : "text-primary"
                          }`}
                        >
                          {item.status}
                        </td>
  
                        {/* Actions Column */}
                        {isFirstRowOfGroup && (
                          <td rowSpan={groupRowCount}>
                            <div className="d-flex flex-column align-items-center gap-2">
                              {item.dispatch_id === editingDispatchId ? (
                                <div className="update-status-form">
                                  <div className="form-group d-flex flex-column gap-2 align-items-center">
                                    <h4 className="text-bold mb-0">Update Status:</h4>
                                    <select
                                      className="form-select w-auto"
                                      value={updatedStatus}
                                      onChange={(e) => setUpdatedStatus(e.target.value)}
                                    >
                                      <option value="Added">Added</option>
                                      <option value="Dispatched">Dispatched</option>
                                    </select>
                                  </div>
  
                                  <div className="d-flex gap-2">
                                    <button
                                      className="btn btn-sm btn-success"
                                      onClick={saveUpdates}
                                    >
                                      {isIconLoaded ? (
                                        <FaSave className="me-2" />
                                      ) : (
                                        <span className="d-none d-sm-inline">Save</span>
                                      )}
                                    </button>
  
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() => setEditingDispatchId(null)}
                                    >
                                      {isIconLoaded ? (
                                        <FaTimes className="me-2" />
                                      ) : (
                                        <span className="d-none d-sm-inline">Cancel</span>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                isEditable && (
                                  <>
                                    {permissible_roles.includes('edit_dispatch')
                                      ?
                                      (<div className="d-flex gap-2">
                                        <button
                                          className="btn btn-sm btn-warning"
                                          onClick={() => handleUpdate(item.dispatch_id)}
                                        >
                                          {isIconLoaded ? (
                                            <FaEdit className="me-2" />
                                          ) : (
                                            <span className="d-none d-sm-inline">Edit</span>
                                          )}
                                        </button>
                                        <button
                                          className="btn btn-sm btn-danger"
                                          onClick={() => deleteSubDispatch(index)}
                                        >
                                          {isIconLoaded ? (
                                            <FaTrash className="me-2" />
                                          ) : (
                                            <span className="d-none d-sm-inline">Delete</span>
                                          )}
                                        </button>
                                      </div>)
                                      :
                                      (<div className="d-flex gap-2">
                                        <button
                                          className="btn btn-sm btn-warning"
                                          onClick={() => rolePermission()}
                                        >
                                          {isIconLoaded ? (
                                            <FaEdit className="me-2" />
                                          ) : (
                                            <span className="d-none d-sm-inline">Edit</span>
                                          )}
                                        </button>
                                        <button
                                          className="btn btn-sm btn-danger"
                                          onClick={() => rolePermission()}
                                        >
                                          {isIconLoaded ? (
                                            <FaTrash className="me-2" />
                                          ) : (
                                            <span className="d-none d-sm-inline">Delete</span>
                                          )}
                                        </button>
                                      </div>)
                                      }
                                  </>
                                )
                              )}
                            </div>
                          </td>
                        )}
  
                        <td>
                          {item.date
                            ? `${DateTimeUtil.timestampFromDBToISODate(item.date)} ${DateTimeUtil.timestampFromDBToTimeAMPM(item.date)}`
                            : "N/A"}
                        </td>
                        {isFirstRowOfGroup && (
                          <td rowSpan={groupRowCount}>
                            {item.dispatch_date
                              ? `${DateTimeUtil.timestampFromDBToISODate(item.dispatch_date)} ${DateTimeUtil.timestampFromDBToTimeAMPM(item.dispatch_date)}`
                              : "N/A"}
                          </td>
                        )}
                        <td>
                          {item.accept_at
                            ? `${DateTimeUtil.timestampFromDBToISODate(item.accept_at)} ${DateTimeUtil.timestampFromDBToTimeAMPM(item.accept_at)}`
                            : "N/A"}
                        </td>
                        <td>{item.created_by || "N/A"}</td>
                        <td>{item.user || "N/A"}</td>
                        <td>{item.accept_user || "N/A"}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="13" className="text-center text-muted">
                      No dispatches available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
  
};

const CreateDispatch = ({ setDispatches, closeCreate }) => {
  const [newDispatches, setNewDispatches] = useState([]);
  const [selectedHub, setSelectedHub] = useState("Select Hub");
  const [selectedProduct, setSelectedProduct] = useState("Select Product");
  const [quantity, setQuantity] = useState(1);
  const { hubProducts, setHubWiesProduct} = useInventoryContext();
  const generateDispatchId = () => `DISP-${Date.now()}`;
  const generateSubDispatchId = () =>
    `SUB-${Date.now()}-${Math.random().toString(36).substr(2, 4)}`;

  const addProductToDispatch = () => {
    if (
      selectedProduct !== "Select Product" &&
      selectedHub !== "Select Hub" &&
      quantity > 0
    ) {
      setNewDispatches((prev) => {
        const existingDispatchIndex = prev.findIndex(
          (dispatch) =>
            dispatch.productName === selectedProduct &&
            dispatch.hub === selectedHub
        );

        if (existingDispatchIndex > -1) {
          const updatedDispatches = [...prev];
          updatedDispatches[existingDispatchIndex].quantity += quantity;
          updatedDispatches[existingDispatchIndex].date = new Date();
          return updatedDispatches;
        } else {
          return [
            ...prev,
            {
              productName: selectedProduct,
              hub: selectedHub,
              quantity,
              date: new Date(),
              dispatch_sub_id: generateDispatchId(),
              status: "Added",
              created_by: localStorage.getItem("loggedIn_user"),
              created_user_id: localStorage.getItem("userId"),
            },
          ];
        }
      });

      setSelectedProduct("Select Product");
      setQuantity(1);
    }
  };

  const createDispatch = async () => {
    try {
      const dispatchId = generateDispatchId();
      const addInventory = new AddInventory();
      const promises = newDispatches.map(async (item) => {
        const data = await addInventory.addDispatch({
          ...item,
          dispatch_id: dispatchId,
        });
        return data;
      });

      const results = await Promise.all(promises);
      const successfulDispatches = results.filter((result) => result !== null);
      setDispatches((prev) => [...prev, ...successfulDispatches]);
      closeCreate();
    } catch (error) {
      console.error("Error creating dispatches:", error);
    }
  };

  const deleteProductFromDispatch = (index) => {
    setNewDispatches((prev) => prev.filter((_, i) => i !== index));
  };

  const need = useMemo(() => {
    const filteredProducts = hubProducts.find(
      (item) =>
        item.hubName === selectedHub && item.product_name === selectedProduct
    );
  
    if (!filteredProducts) {
      return 0; 
    }
  
    const { stock = 0, B2C_predicted_orders = 0, B2B_predicted_orders = 0 } = filteredProducts;

    const data = stock - B2C_predicted_orders - B2B_predicted_orders;
  
    console.log(data, filteredProducts);
  
    return data;
  }, [selectedHub, selectedProduct, hubProducts]);
  
  return (
    <div
      className="modal show fade"
      style={{
        display: "block",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 1050
      }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg dispatchdata" style={{ width: "80%"}}>
        <div className="modal-content">
  <div className="modal-header">
    <h5 className="modal-title">Add New Dispatch</h5>
    <button
      type="button"
      className="btn-close"
      aria-label="Close"
      onClick={closeCreate}
    ></button>
  </div>

  <div className="modal-body" style={{
    overflowY: "scroll",
    height: "400px"
  }}>
    {/* Form Section */}
    <div className="mb-4">
      <h6 className="text-primary mb-2">Dispatch Details</h6>
      <form className="px-4 py-2 bg-light rounded shadow-sm">
        {/* Hub and Product Dropdowns */}
        <div className="row gy-2">
          {/* Select Hub */}
          <div className="col-md-6 d-flex align-items-center justify-content-between">
            <label
              htmlFor="hub"
              className="form-label fw-semibold mb-0 me-2"
            >
              Select Hub:
            </label>
            <div style={{ flex: 1 }}>
              <HubDropdown
                selectedHub={selectedHub}
                setSelectedHub={setSelectedHub}
                defaultHub="Select Hub"
              />
            </div>
          </div>

          {/* Select Product */}
          <div className="col-md-6 d-flex align-items-center justify-content-between">
            <label
              htmlFor="product"
              className="form-label fw-semibold mb-0 me-2"
            >
              Select Product:
            </label>
            <div style={{ flex: 1 }}>
              <ProductDropdown
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                defaultProduct="Select Product"
              />
            </div>
          </div>
        </div>

        {/* Quantity and Available Quantity */}
        <div className="row gy-2 mt-2">
          {/* Input Quantity */}
          <div className="col-md-6 d-flex align-items-center justify-content-between">
            <label
              htmlFor="quantity"
              className="form-label fw-semibold mb-0 me-2"
            >
              Quantity:
            </label>
            <input
              id="quantity"
              type="number"
              className="form-control"
              style={{ maxWidth: "150px" }}
              placeholder="Dispatched Quantity"
              value={quantity}
              min="1"
              onChange={(e) => setQuantity(Number(e.target.value))}
            />
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-between" title="Shown quantity is without buffer added">
          <label
            htmlFor="quantity"
            className="form-label fw-semibold mb-0 me-2"
          >
            Stock Need/Exceed:
          </label>

            <input
              id="quantity"
              type="number"
              className="form-control text-muted"
              style={{ maxWidth: "150px" }}
              placeholder="Need /Exceed"
              value={need}
              readOnly
            />
          </div>


          
        </div>

        <div className="d-flex justify-content-end mt-2">
          <button
            type="button"
            className="btn btn-primary px-5 py-2 fw-semibold"
            onClick={addProductToDispatch}
          >
            Add to Dispatch
          </button>
        </div>
      </form>
    </div>

    <hr />

    {/* Dispatch Table */}
    <div>
      <h6 className="text-primary mb-3">Dispatch Products</h6>
      <div className="table-responsive">
        <table className="table table-bordered table-striped">
          <thead className="table-dark">
            <tr>
              <th>Product Name</th>
              <th>Hub</th>
              <th>Quantity</th>
              <th>Date</th>
              <th>Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {newDispatches.length > 0 ? (
              newDispatches.map((dispatch, index) => (
                <tr key={index}>
                  <td>{dispatch.productName}</td>
                  <td>{dispatch.hub}</td>
                  <td>{dispatch.quantity}</td>
                  <td>{DateTimeUtil.timestampToDate(dispatch.date)}</td>
                  <td>{DateTimeUtil.timestampToTimeAMPM(dispatch.date)}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => deleteProductFromDispatch(index)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-muted">
                  No products added yet.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="alert alert-info mt-4">
        <strong>Note:</strong> If the selected product for a hub already exists
        in the dispatch list, its quantity will be updated instead of creating a
        new entry.
      </div>
    </div>
  </div>

  <div className="modal-footer">
    <button className="btn btn-success" onClick={createDispatch}>
      Create Dispatch
    </button>
    <button className="btn btn-secondary" onClick={closeCreate}>
      Cancel
    </button>
  </div>
</div>

      </div>
    </div>
  );
};

const UserGuideModal = ({ showGuide, setShowGuide }) => {
  return (
    showGuide && (
      <div
        className="modal show fade"
        style={{
          display: "block",
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: 1050,
          overflowY: "scroll",
        }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-lg" style={{ width: "70%" }}>
          <div className="modal-content" style={{ backgroundColor: "#f8f9fa" }}>
            {/* Header */}
            <div
              className="modal-header"
              style={{ backgroundColor: "#007bff", color: "white" }}
            >
              <h5 className="modal-title">User Guide</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowGuide(false)} // Close the modal
              ></button>
            </div>

            {/* Body */}
            <div className="modal-body">
              <h4 style={{ color: "#007bff" }}>Dispatch Overview</h4>
              <p style={{ color: "#6c757d" }}>
                This section allows you to manage the dispatch of{" "}
                <span style={{ fontWeight: "bold", color: "#007bff" }}>
                  products
                </span>{" "}
                from{" "}
                <span style={{ fontWeight: "bold", color: "#007bff" }}>
                  hubs
                </span>{" "}
                to{" "}
                <span style={{ fontWeight: "bold", color: "#007bff" }}>
                  destinations
                </span>
                . You can{" "}
                <span style={{ fontWeight: "bold", color: "#28a745" }}>
                  add new dispatches
                </span>
                ,{" "}
                <span style={{ fontWeight: "bold", color: "#ffc107" }}>
                  edit
                </span>{" "}
                them, or{" "}
                <span style={{ fontWeight: "bold", color: "#dc3545" }}>
                  delete
                </span>{" "}
                them as needed.
              </p>

              <div className="row mt-4">
                {/* Left Column - Creating a New Dispatch */}
                <div className="col-6">
                  <h5 style={{ color: "#28a745" }}>Creating a New Dispatch</h5>
                  <ul
                    className="d-flex flex-column gap-2"
                    style={{ listStyleType: "square" }}
                  >
                    <li>
                      <span style={{ fontWeight: "bold", color: "#007bff" }}>
                        Click
                      </span>{" "}
                      to{" "}
                      <button
                        className="btn btn-primary p-2"
                        style={{ fontSize: "0.9rem" }}
                      >
                        Create New Dispatch
                      </button>{" "}
                      button.
                    </li>
                    <li>
                      <span style={{ fontWeight: "bold", color: "#007bff" }}>
                        Select
                      </span>{" "}
                      a{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#007bff",
                          backgroundColor: "rgba(0, 123, 255, 0.1)",
                          padding: "0.2rem 0.4rem",
                          borderRadius: "4px",
                        }}
                      >
                        hub
                      </span>
                      ,{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#007bff",
                          backgroundColor: "rgba(0, 123, 255, 0.1)",
                          padding: "0.2rem 0.4rem",
                          borderRadius: "4px",
                        }}
                      >
                        product
                      </span>
                      , and{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#007bff",
                          backgroundColor: "rgba(0, 123, 255, 0.1)",
                          padding: "0.2rem 0.4rem",
                          borderRadius: "4px",
                        }}
                      >
                        quantity
                      </span>{" "}
                      from the dropdowns.
                    </li>
                    <li>
                      <span style={{ fontWeight: "bold", color: "#007bff" }}>
                        Click
                      </span>{" "}
                      to{" "}
                      <button
                        className="btn btn-primary p-2"
                        style={{ fontSize: "0.9rem" }}
                      >
                        Add Product
                      </button>{" "}
                      to add the selected item to the dispatch list.
                    </li>
                    <li>
                      <span style={{ fontWeight: "bold", color: "#007bff" }}>
                        Click
                      </span>{" "}
                      to{" "}
                      <button
                        className="btn btn-primary p-2"
                        style={{ fontSize: "0.9rem" }}
                      >
                        Create Dispatch
                      </button>{" "}
                      to finalize and submit the dispatch.
                    </li>
                  </ul>
                  <div
                    style={{
                      backgroundColor: "#d1ecf1",
                      padding: "1rem",
                      borderRadius: "5px",
                    }}
                  >
                    <strong>Note:</strong> If a product is already added, its
                    quantity will be updated instead of creating a new entry.
                  </div>
                </div>

                {/* Right Column - Editing and Deleting Dispatches */}
                <div className="col-6">
                  <h5 style={{ color: "#ffc107" }}>
                    Editing and Deleting Dispatches
                  </h5>
                  <ul style={{ listStyleType: "circle" }}>
                    <li>
                      <span style={{ fontWeight: "bold", color: "#007bff" }}>
                        Click
                      </span>{" "}
                      to{" "}
                      <button
                        className="btn btn-primary p-2"
                        style={{ fontSize: "0.9rem" }}
                      >
                        Update
                      </button>{" "}
                      to change the{" "}
                      <span style={{ fontWeight: "bold", color: "#007bff" }}>
                        status
                      </span>{" "}
                      of a dispatch and{" "}
                      <span style={{ fontWeight: "bold", color: "#007bff" }}>
                        quantity
                      </span>{" "}
                      of a product.
                    </li>
                    <li>
                      <span style={{ fontWeight: "bold", color: "#007bff" }}>
                        Click
                      </span>{" "}
                      to{" "}
                      <button
                        className="btn btn-danger p-2"
                        style={{ fontSize: "0.9rem" }}
                      >
                        Delete
                      </button>{" "}
                      to remove a dispatch from the list.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Dispatch;

