import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../Utility";
import db from "../../Firebase";
import { useInventoryContext } from "./InventoryContext";

const useFetchUserRole = () => {
    const navigate = useNavigate();
    const {setUser} = useInventoryContext();
    useEffect(() => {
      const fetchUserRole = async () => {
        try {
          const userId = localStorage.getItem("userId");
  
          if (!userId) {
            handleLogout();
            navigate("/permission_denied");
            return;
          }
  
          const querySnapshot = await db
            .collection("users")
            .where("user_id", "==", userId)
            .limit(1)
            .get();
  
          if (!querySnapshot.empty) {
            const user = querySnapshot.docs[0].data();
            localStorage.setItem("role", user.role);
            if(user.role === "Hub Manager"){
                localStorage.setItem("hub_name", user.hub_name);
            }
            setUser(user);
            console.log(user);
            
          } else {
            handleLogout();
            navigate("/permission_denied");
          }
        } catch (error) {
          console.error("Error fetching user role:", error);
          handleLogout();
          navigate("/permission_denied");
        }
      };
  
      fetchUserRole();
    }, [navigate]);
  };
  
  export default useFetchUserRole;