import React, { useState, useEffect, useContext } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import GlobalContext from "../../context/GlobalContext";
import db from "../../Firebase";
//Component imports
import TopPanel from "../../TopPanel";
import Sidebar from "../../Sidebar";
import Footer from "../../Footer";
import HistoryTable from "./HistoryTable";
import MessageBox from "./MessageBox";

//globle variable
import { newCommunicationHistory } from "./utility/queries";
import { cantCreateNewCampaign, notAuthorized} from "./utility/Toast";
import CheckboxGroup from "./utility/CheckboxGroup";
import { CommunicationContext } from "./CommunicationContext";
import EmailPreview from "./preview/EmailPreview";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// import { sendWhatsAppMessage } from "./sensWhatsap";
import { getMessageTamplats, getTextlocalMessageTamplats, sendWhatsAppMessage } from "./WATI API FUNCTIONS/wati";
import { sendSMS } from "./WATI API FUNCTIONS/sendSMS";
import CampaignDetailsForm from "./utility/CampaignDetailsForm";
import TrialUsersFilter from "./utility/TrialUsersFilter";
import AbandonedCartFilter from "./utility/AbandonedCartFilter";
import moment from "moment";
import { BsExclamationTriangleFill } from "react-icons/bs";


const customerPropertyNames = ["status", "platform", "subscription_type", "source",  ];

const customerPropertyOptionsMap = {
  platform: [
    { value: "website", name: "Web", label: "Web" },
    { value: "ios", name: "ios", label: "iOS" },
    { value: "android", name: "android", label: "Android" }
  ],
  status: [
    { value: "subscribers", name: "subscribers", label: "Subscribers" },
    { value: "active subscribers", name: "active subscribers", label: "Active Subscribers" },
    {  value: "paused customers",  name: "paused customers",  label: "Paused Customers"},
  ],
  subscription_type: [
    { value: "Custom", label: "Custom", name: "Custom" },
    { value: "Everyday", label: "Everyday", name: "Everyday" },
    { value: "On-Interval", label: "On-Interval", name: "On-Interval" },
    { label: "One Time", value: "One Time", name: "One Time" },
  ],
  source: [
    { value: "Facebook", name: "Facebook", label: "Facebook" },
    { value: "Instagram", name: "Instagram", label: "Instagram" },
    { value: "Google", name: "Google", label: "Google" },
    { value: "Twitter", name: "Twitter", label: "Twitter" },
  ],
};


// Component 1: Communication
const Communication_Home = () => {
  const { permissible_roles } = useContext(GlobalContext);
  const {  hub, msgData, setWhatsappData, setSmsData, historyData, whatsappTemplates, setWhatsappTemplate, smsTemplates, setSmsTemplates, newCampaign, setNewCampaign,  customerData, search, setSearch, filter, data, setData } = useContext(CommunicationContext);
  const [reload, setReload] = useState(false);
  const chunkArray = (arr, size) => {
    return arr.reduce((chunks, item, index) => {
      const chunkIndex = Math.floor(index / size);
      if (!chunks[chunkIndex]) chunks[chunkIndex] = [];
      chunks[chunkIndex].push(item);
      return chunks;
    }, []);
  };
  
 

const fetchCartData = async (date) => {
  try {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const snapshot = await db.collection("cart_data")
      .where("update_date", ">=", formattedDate) 
      // .limit(20)
      .get();

    const cart_data = snapshot.docs
      .map(doc => ({ id: doc.id, ...doc.data() }))
      .filter(doc => doc.products && doc.products.length > 0); 

    return cart_data;
  } catch (error) {
    console.error("Error fetching cart data:", error);
    return [];
  }
};



const fetchTrailedUser = async (date) => {
  const formattedDate = new Date(date);
  formattedDate.setHours(0, 0, 0, 0);

  try {
    const snapshot = await db.collection("customers_data")
      .where("created_date", ">=", formattedDate)
      .get();

    const customers = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    if (customers.length === 0) return [];

    const customerIds = customers.map(item => item.customer_id).filter(Boolean);
    const customerChunks = chunkArray(customerIds, 10);

    let orderData = [];

    for (const chunk of customerChunks) {
      const querySnapshot = await db.collection("order_history")
        .where("customer_id", "in", chunk)
        .get();
      orderData.push(...querySnapshot.docs.map(doc => doc.data()));
    }

    const updatedCustomers = customers.map(customer => {
      const userOrders = orderData.filter(order => order.customer_id === customer.customer_id);

      let trailedStatus = { id: "", status: userOrders.length > 0 };

      for (const order of userOrders) {
        if (!trailedStatus.id) {
          trailedStatus.id = order.order_id;
        } else if (trailedStatus.id !== order.order_id || order.order_type !== "OT" || order.status !== "1") {
          trailedStatus.status = false;
          break;
        }
      }

      return { ...customer, trailedUsers: trailedStatus.status };
    });

    return updatedCustomers.filter(customer => customer.trailedUsers);
  } catch (error) {
    console.error("Error fetching trial users:", error);
    return [];
  }
};



  const fetchCustomer = async (filter) => {
    if (!filter) return [];
  
    let customers = [];
  
    if (filter?.AbandendCart?.status) {
      customers = await fetchCartData(filter.AbandendCart.date);
    }
  
    if (filter?.TrailedUser?.status) {
      if (customers.length === 0) {
        customers = await fetchTrailedUser(filter.TrailedUser.date);
        customers = customers.filter(item => item.trailedUsers);
      } else {

        const date = new Date(filter.TrailedUser.date);
        date.setHours(0, 0, 0, 0);
        const snapshot = await db.collection("customers_data")
          .where("created_date", ">=", date)
          .get();

        let customers = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        if (customers.length === 0) return [];

        const customerIds = customers.map(item => item.customer_id).filter(Boolean);
        const customerChunks = chunkArray(customerIds, 10);

        let orderData = [];

        for (const chunk of customerChunks) {
          const querySnapshot = await db.collection("order_history")
            .where("customer_id", "in", chunk)
            .get();
          orderData.push(...querySnapshot.docs.map(doc => doc.data()));
        }

        const updatedCustomers = customers.map(customer => {
          const userOrders = orderData.filter(order => order.customer_id === customer.customer_id);

          let trailedStatus = { id: "", status: userOrders.length > 0 };

          for (const order of userOrders) {
            if (!trailedStatus.id) {
              trailedStatus.id = order.order_id;
            } else if (trailedStatus.id !== order.order_id || order.order_type !== "OT" || order.status !== "1") {
              trailedStatus.status = false;
              break;
            }
          }

          return { ...customer, trailedUsers: trailedStatus.status };
        });

        customers = updatedCustomers.filter(customer => customer.trailedUsers);
      }
    }
  
    // Handle subscription filters
    if (filter?.subscription_type?.length > 0 || filter?.status?.length > 0) {
      if (customers.length > 0) {
        const customerIds = customers.map(item => item.customer_id);
        const customerChunks = chunkArray(customerIds, 10);
      
        let subscriptionData = [];
        for (const chunk of customerChunks) {
          let query = db.collection("subscriptions_data").where("customer_id", "in", chunk);
      
          if (filter?.subscription_type?.length > 0 && filter?.subscription_type.length !== 3) {
            query = query.where("subscription_type", "in", filter.subscription_type);
          }
      
          if (filter?.status?.length === 1 && filter.status[0] !== "subscribers") {
            const status = filter.status[0] === "active subscribers" ? "1" : "0";
            query = query.where("status", "==", status);
          }
      
          const snapshot = await query.get();
          subscriptionData.push(...snapshot.docs.map(doc => doc.data()));
        }
      
        const uniqueCustomers = {};
        
        customers.forEach(({ customer_id }) => {
          uniqueCustomers[customer_id] = {
            customer_id,
            subscriptionType: new Set(),
            status: "0",
          };
        });
      
        subscriptionData.forEach(({ customer_id, subscription_type, status }) => {
          if (!uniqueCustomers[customer_id]) {
            uniqueCustomers[customer_id] = {
              customer_id,
              subscriptionType: new Set(),
              status: "0",
            };
          }
      
          uniqueCustomers[customer_id].subscriptionType.add(subscription_type);
          if (status === "1") {
            uniqueCustomers[customer_id].status = "1";
          }
        });
        customers = Object.values(uniqueCustomers).map(({ customer_id, subscriptionType, status }) => ({
          customer_id,
          subscriptionType: [...subscriptionType],
          status,
        }));
      
      } else {
        let query = db.collection("subscriptions_data");
      
        if (filter?.subscription_type?.length > 0 && filter?.subscription_type.length !== 3) {
          query = query.where("subscription_type", "in", filter.subscription_type);
        }
      
        if (filter?.status?.length === 1 && filter.status[0] !== "subscribers") {
          const status = filter.status[0] === "active subscribers" ? "1" : "0";
          query = query.where("status", "==", status);
        }
      
        // const snapshot = await query.limit(100).get();
        const snapshot = await query.get();
        customers = snapshot.docs.map(doc => doc.data());
        const uniqueCustomers = {};
      
        customers.forEach(({ customer_id, subscription_type, status }) => {
          if (!uniqueCustomers[customer_id]) {
            uniqueCustomers[customer_id] = {
              customer_id,
              subscriptionType: new Set(),
              status: "0",
            };
          }
      
          uniqueCustomers[customer_id].subscriptionType.add(subscription_type);
      
          if (status === "1") {
            uniqueCustomers[customer_id].status = "1";
          }
        });
      
        customers = Object.values(uniqueCustomers).map(({ customer_id, subscriptionType, status }) => ({
          customer_id,
          subscriptionType: [...subscriptionType],
          status,
        }));
      }
      
      console.log(customers);
      
    }
    
    if (customers.length === 0) {
      let query = db.collection("customers_data");
    
      if (filter?.hub?.length > 0 && filter.hub.length !== hub.length) {
        query = query.where("hub_name", "in", filter.hub);
      }
      if (filter?.source?.length > 0 && filter.source.length != 4) {
        query = query.where("source", "in", filter.source);
      }
      if (filter?.platform?.length > 0 && filter.platform.length != 3) {
        query = query.where("platform", "in", filter.platform);
      }
    
      // const snapshot = await query.limit(100).get();
      const snapshot = await query.get();
      customers = snapshot.docs.map(doc => doc.data());
    } else {
      const customerIds = customers.map(item => item.customer_id);
      const customerChunks = chunkArray(customerIds, 10);
    
      let newCustomers = [];
    
      for (const chunk of customerChunks) {
        let query = db.collection("customers_data").where("customer_id", "in", chunk);
    
        if (filter?.hub?.length > 0 && filter.hub.length !== hub.length) {
          query = query.where("hub_name", "in", filter.hub);
        }
        if (filter?.source?.length > 0 && filter.source.length != 4) {
          query = query.where("source", "in", filter.source);
        }
        if (filter?.platform?.length > 0 && filter.platform.length != 3) {
          query = query.where("platform", "in", filter.platform);
        }
    
        const snapshot = await query.get();
        newCustomers.push(...snapshot.docs.map(doc => doc.data()));
      }
      const uniqueCustomers = {};
      customers.forEach(({ customer_id, ...rest }) => {
        uniqueCustomers[customer_id] = { customer_id, ...rest };
      });
    
      newCustomers.forEach(({ customer_id, ...rest }) => {
        if (!uniqueCustomers[customer_id]) {
          uniqueCustomers[customer_id] = { customer_id, ...rest };
        } else {
          uniqueCustomers[customer_id] = { ...uniqueCustomers[customer_id], ...rest };
        }
      });
    
      customers = Object.values(uniqueCustomers);
      if (filter?.hub?.length > 0 && filter.hub.length !== hub.length) {
        customers = customers.filter(item => filter.hub.includes(item.hub_name));
      }
      if (filter?.source?.length > 0 && filter.source.length <= 10) {
        customers = customers.filter(item => filter.source.includes(item.source));
      }
      if (filter?.platform?.length > 0 && filter.platform.length <= 10) {
        customers = customers.filter(item => filter.platform.includes(item.platform));
      }
    }
    
    console.log(customers);
    return customers;
  };
  const getData = async () => {
    const data = await fetchCustomer(filter);
    setData(data);
  };
  
  
  const handleNewCampaign = () => {
    if (newCampaign) {
      cantCreateNewCampaign();
      return;
    }
    if (1 || permissible_roles.includes("newCampaign")) {
      setNewCampaign(true);
    } else {
      notAuthorized();
    }
  };
  
  useEffect(()=>{
    const test = async ()=>{
      const data = await getMessageTamplats();
      setWhatsappTemplate(data);
      const smsTemplates = await getTextlocalMessageTamplats();
      setSmsTemplates(smsTemplates?.data?.templates || []);
    }
    test();
  }, [])
  useEffect(()=>{
    if(whatsappTemplates.length > 0) {
      setWhatsappData(prev => ({...prev, message: whatsappTemplates[0]?.body, title: whatsappTemplates[0]?.elementName, template: whatsappTemplates[0]}))
    }
  }, [whatsappTemplates])
  useEffect(()=>{
    if(smsTemplates.length > 0) {
      setSmsData(prev => ({...prev, message: smsTemplates[0]?.body, title: smsTemplates[0].title, template: smsTemplates[0]}));
    }else{
      setSmsData(prev => ({...prev, message: "", title: ""}))
    }
  }, [smsTemplates])
  
  
  return (
    <div className="container-scroller bg-light"> 
      <TopPanel />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel container-scroller">
          <div className="content-wrapper">
            <div className="col-lg-12 grid-margin">
              <div className="card shadow-sm border-0"> 
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingBottom: "10px",
                      borderBottom: "2px solid #dee2e6",
                    }}
                  >
                    <h3 className="card-title fs-4 text-dark">
                      📢 Communication Management
                    </h3>
                    <button
                      type="button"
                      className="btn btn-primary btn-lg"
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#007bff",
                        borderColor: "#0056b3",
                        padding: "8px 16px",
                        fontSize: "16px",
                        borderRadius: "8px",
                      }}
                      onClick={handleNewCampaign}
                    >
                      + New Campaign
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            {newCampaign && <NewCampaign getData={getData} />}
            <div className="card shadow-sm border-0 mt-4"> 
              <div className="card-body">
                <h4 className="text-primary fs-5 mb-3 fw-bold">📜 Campaign History</h4>
                {!reload && <HistoryTable/>}
              </div>
            </div>
            <Summary setReload={setReload} />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
  
};


// Component 2: Summary
const Summary = ({setReload}) => {
  const {
    title,
    date,
    filter,
    selectedValue,
    msgData,
    summary,
    setSummary,
    handleLaunch,
    handleDiscard,
    data,
    smsParamMap,
  } = useContext(CommunicationContext);
  const [launch, setLanching] = useState(-1);
  const [complete, setComplete] = useState(false);
  const [whatsappLog, setWhatsappLog] = useState([]);
  const [smsLog, setSmsLog] = useState([]);
  const navigate = useNavigate();

  const [showConfirm, setShowConfirm] = useState(false);

  const handleLaunch1 = () => {
    setShowConfirm(true); // Show confirmation popup
  };

  const confirmLaunch = () => {
    setShowConfirm(false);
    handleSend(); // Proceed with launching
  };

  const getBadgeClass = (type) => {
    switch (type.toLowerCase()) {
      case "Custom":
        return "badge bg-warning text-dark"; 
      case "EveryDay":
        return "badge bg-secondary text-white"; 
      case "interval":
        return "badge bg-danger text-white"; 
      case "One-Time":
        return "badge bg-primary text-white"; 
      default:
        return "badge bg-info text-dark"; 
    }
  };

  const getBadgeTextClass = (source) => {
    switch (source.toLowerCase() || source) {
      case "instgram":
        return "badge bg-warning text-dark"; 
      case "add":
        return "badge bg-info text-dark"; 
      case "google":
        return "badge bg-danger";
      case "facebook":
        return "badge bg-primary text-white"; 
      default:
        return "badge bg-info text-dark";
    }
  };
  const getBadgeTextClassPlatform = (source) => {
    switch (source.toLowerCase() || source) {
      case "ios":
        return "badge bg-warning text-dark"; 
      case "android":
        return "badge bg-info text-dark"; 
      case "website":
        return "badge bg-danger";
      case "backend":
        return "badge bg-primary text-white"; 
      default:
        return "badge bg-info text-dark";
    }
  };

  const getUser = async () => {
    try {
    const loggedInUser = localStorage.getItem("userId");
    if (!loggedInUser) throw new Error("User not found in localStorage");

    const userSnap = await db.collection("users").where("user_id", "==", loggedInUser).limit(1).get();
    
    if (!userSnap.empty) {
      return userSnap.docs[0].data();
    } else {
      throw new Error("User not found in database");
    }
  } catch (error) {
    console.error("Error fetching user:", error);
    return null; 
  }
};
console.log(data);

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const handleSend = async () => {
  setLanching(0);
  const user = await getUser();

  if (!user) {
    toast.error("User session expired. Redirecting to login...");
    localStorage.clear();
    navigate("/login");
    return;
  }

  setLanching(1);

  const campaignData = {
    title,
    filter,
    stats: {
      total: data.length,
      sms: { sent: 0, failed: 0 },
      whatsapp: { sent: 0, failed: 0 },
      email: { sent: 0, failed: 0 },
    },
    msgData,
    medium: selectedValue,
  };
  console.log(data);
  
  const customerDetails = data;
  
  
  // const customerDetails = [
  //   // { customer_name: "Vishal", customer_phone: "9461900848", customer_email: "vishal@gmail.com" },
  //   // { customer_name: "Pravesh", customer_email: "pravesh@gmail.com", customer_phone: "9001377465" },
  //   { customer_name: "shruti", customer_email: "shruti@gmail.com", customer_phone: "9650515059", customer_address: "test adderess", wallet_balance: 99},
  //   { customer_name: "shruti", customer_email: "shruti@gmail.com", customer_phone: "9873290043", customer_address: "test adderess", wallet_balance: 99},
  //   { customer_name: "muskan", customer_email: "muskan@gmail.com", customer_phone: "8588085280", customer_address: "test adderess", wallet_balance: 99},
  // ];

  setReload(true);
  setLanching(2);

  if (selectedValue.includes("SMS")) {
    await delay(500);
    const log = await sendSMSMessage(customerDetails);
    campaignData.stats.sms.sent = log.filter((e) => e.status === "success").length;
    campaignData.stats.sms.failed = log.length - campaignData.stats.sms.sent;
  }

  setLanching(3);
  if (selectedValue.includes("Email")) {
    await delay(500);
  }

  setLanching(4);
  if (selectedValue.includes("WhatsApp")) {
    await delay(500);
    const log = await sendWhatsApp(customerDetails);
    campaignData.stats.whatsapp.sent = log.filter((e) => e.status === "success").length;
    campaignData.stats.whatsapp.failed = log.length - campaignData.stats.whatsapp.sent;
  }

  setLanching(5);

  try {
    await newCommunicationHistory(campaignData, user);
  } catch (error) {
    toast.error("Failed to create campaign. Please try again later.");
    console.error("Error creating campaign:", error);
  } finally {
    setComplete(true);
    setLanching(6);
    setReload(false);
  }
};

// Function to send WhatsApp messages
const sendWhatsApp = async (customers) => {
  const log = [];

  for (const customer of customers) {
    const parameters = msgData?.whatsapp?.prameter
      ? Object.entries(msgData.whatsapp.prameter).map(([key, value]) => ({
          name: key,
          value: customer[value] || value,
        }))
      : [];

    try {
      const response = await sendWhatsAppMessage(`91${customer.customer_phone}`, msgData.whatsapp.template.elementName, parameters);
      log.push({ customer_name: customer.customer_name, status: response ? "success" : "failed", validWhatsAppNumber: response.validWhatsAppNumber });
    } catch (e) {
      console.error("Failed to send WhatsApp message:", e);
      log.push({ customer_name: customer.customer_name, status: "failed", error: e.message });
    }
  }

  setWhatsappLog(log);
  return log;
};

// Function to send SMS messages
const sendSMSMessage = async (customers) => {
  const log = [];

  for (const customer of customers) {
    try {
      const customMessage = parseTemplate(customer, msgData.sms.template.body, smsParamMap);
      const response = await sendSMS(customer.customer_phone, customMessage);
      log.push({ customer_name: customer.customer_name, status: response?.status === 200 ? 'success' : "failed" });
    } catch (e) {
      console.error("Failed to send SMS message:", e);
      log.push({ customer_name: customer.customer_name, status: "failed" });
    }
  }

  setSmsLog(log);
  return log;
};

// Template parsing function
const parseTemplate = (customer, template, values) => {
  return template.replace(/%%\|([\w]+)\^.*?%%/g, (match, key) => customer[values[key]] || values[key] || key);
};

// CSV download function
const downloadCSV = (log, filename) => {
  if (!log || log.length === 0) {
    console.warn("No data to export.");
    return;
  }

  const headers = Object.keys(log[0]).join(",") + "\n";
  const rows = log.map((entry) => Object.values(entry).map((value) => `"${value}"`).join(",")).join("\n");
  const csvContent = headers + rows;

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

// Handle CSV download
const handleDownload = () => {
  if (whatsappLog.length) downloadCSV(whatsappLog, `whatsapp_log_${new Date().toISOString()}.csv`);
  if (smsLog.length) downloadCSV(smsLog, `sms_log_${new Date().toISOString()}.csv`);
};

  return (
    summary && (
      <>
      {launch !== -1 && (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backdropFilter: "blur(10px)",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      zIndex: 9999,
    }}
  >
    {launch !== 6 && (
      <div className="text-center text-white">
        <div className="spinner-border text-light" style={{ width: "4rem", height: "4rem" }} role="status"></div>
        <p className="mt-3 fw-semibold">
          {launch === 0 && "Checking user login data..."}
          {launch === 1 && "Preparing data..."}
          {launch === 2 && "Sending SMS..."}
          {launch === 3 && "Sending Email..."}
          {launch === 4 && "Sending WhatsApp message..."}
          {launch === 5 && "Sending campaign summary to the backend..."}
        </p>
        <p className="fw-semibold mt-2">
          Ensure a <span className="fw-bold text-warning">stable network connection</span>  
          and <span className="fw-bold text-warning">keep the system running</span> to avoid interruptions.
        </p>
      </div>
    )}

    {launch === 6 && (
      <div
        className="d-flex flex-column justify-content-center align-items-center rounded shadow-lg p-4"
        style={{
          width: "400px",
          backgroundColor: "#f8f9fa",
          color: "#212529",
          textAlign: "center",
        }}
      >
        <i className="bi bi-check-circle-fill text-success display-4 mb-3"></i>
        <h5 className="mb-2 fw-bold">Message Sent Successfully!</h5>
        <p className="text-muted mb-3">Would you like to download all reports?</p>

        <div className="d-flex gap-3">
          <button
            className="btn btn-success px-4 fw-bold"
            onClick={() => {
              handleDownload(true); // Trigger multi-file download
              setLanching(-1);
              handleDiscard();
              window.location.reload();
            }}
          >
            Yes, Download
          </button>
          <button
            className="btn btn-outline-secondary px-4 fw-bold"
            onClick={() => {
              setLanching(-1);
              handleDiscard();
              window.location.reload();
            }}
          >
            No, Close
          </button>
        </div>
      </div>
    )}
  </div>
)}


      {!complete &&
      <div
        className="loader-overlay w-100 d-flex align-items-center justify-content-center"
        style={{
          backdropFilter: "blur(8px)",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 9998,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
        onClick={handleLaunch}
      >
        <div
          className="bg-light p-4 rounded shadow-lg"
          style={{
            width: "80%",
            minWidth: "700px",
            maxHeight: "90%",
            overflowY: "auto",
            overflowx: "hidden",
          }}
        >
          <h3 className="text-center text-primary mb-4">Campaign Summary</h3>
          <div className="d-flex justify-content-between mb-4">
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-outline-success btn-sm me-2"
            onClick={() => setSummary(false)}
          >
            Edit
          </button>
          <button
            className="btn btn-warning btn-sm"
            onClick={handleLaunch1} // Open confirmation modal
          >
            Launch
          </button>
        </div>
      </div>

      {/* Confirmation Modal */}
   
<Modal
  style={{ zIndex: 9998 }}
  show={showConfirm}
  onHide={() => setShowConfirm(false)}
  centered
>
<Modal.Body className="bg-primary text-white text-center rounded-top p-4">
  <BsExclamationTriangleFill className="fs-1 text-warning mb-3" />
  <h4 className="fw-bold">Confirm Your Action</h4>
  <p className="mb-2">
    This will send messages to <span className="fw-bold">all selected customers</span>.  
    Ensure your filters are correct before proceeding.
  </p>
  <p className="fw-semibold">
    If you proceed, please ensure a <span className="fw-bold">stable network connection</span>  
    and <span className="fw-bold">keep the system running</span> to avoid any interruptions.
  </p>
</Modal.Body>

  <Modal.Footer className="border-0 justify-content-center bg-primary rounded-bottom">
    <Button
      variant="secondary"
      className="px-4 fw-bold"
      onClick={() => setShowConfirm(false)}
    >
      Cancel
    </Button>
    <Button
      variant="success"
      className="px-4 fw-bold"
      onClick={confirmLaunch}
    >
      Yes, Proceed
    </Button>
  </Modal.Footer>
</Modal>


          {/* Campaign Details Section */}
          <div className="mb-4 p-3 border rounded shadow-sm">
            <h5 className="fw-bold mb-3">
              Campaign Details <sup className="text-danger">*</sup>
            </h5>
            <div className="row">
              {/* Column 1 */}
              <div className="col-md-6 d-flex flex-column align-items-start ps-2">
                <div className="mb-2 w-100">
                  <span className="fw-semibold">Title:</span>
                  {title === "" ? (
                    <span className="text-danger ms-2">
                      Please provide a campaign title
                    </span>
                  ) : (
                    <span className="ms-2">{title}</span>
                  )}
                </div>
                <div className="mb-2 w-100">
                  <span className="fw-semibold">Date:</span>
                  <span className="ms-2">{date}</span>
                </div>
              </div>

              {/* Column 2 */}
              <div className="col-md-6 d-flex flex-column align-items-start ps-2">
                <div className="mb-2 w-100">
                  <span className="fw-semibold">No. of Customers:</span>
                  <span className="badge bg-info text-dark ms-2">
                    {data?.length }
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* Applied Filters Section */}
          <div className="mb-4 p-3 border rounded shadow-sm">
            <h5 className="fw-bold mb-3">
              Applied Filters <sup className="text-danger">*</sup>
            </h5>

            {filter ? (
              <div className="row g-3">
                {/* Column 1 */}
                <div className="col-md-6 d-flex flex-column align-items-start">
                {/* Abandoned Cart */}
                <div className="mb-2 w-100">
                  <span className="fw-semibold">Abandoned Cart:</span>
                  {filter?.AbandendCart?.status && filter?.AbandendCart?.date && (
                    <span className="badge bg-primary text-white ms-2">
                      Last Update : {moment(filter.AbandendCart.date).format("DD/MM/YYYY")}
                    </span>
                  )}
                </div>

                  {/* Status */}
                  <div className="mb-2 w-100">
                    <span className="fw-semibold">Status:</span>
                    {filter?.status?.map((type, index) => (
                        <span key={index} className="badge bg-primary text-white ms-2">{type}</span>
                      ))}
                  </div>

                  {/* Platforms */}
                  <div className="mb-2 w-100">
                    <div className="d-flex flex-wrap gap-2 mt-1">
                    <span className="fw-semibold">Platform:</span>
                      {filter.platform.map((plat, index) => (
                        <span key={index} className="badge bg-dark text-white">{plat}</span>
                      ))}
                    </div>
                  </div>

                  {/* Subscription Types */}
                  <div className="mb-2 w-100">
                    <div className="d-flex flex-wrap gap-2 mt-1">
                    <span className="fw-semibold">Subscription Type:</span>
                      {filter.subscription_type.map((type, index) => (
                        <span key={index} className="badge bg-success text-white">{type}</span>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Column 2 */}
                <div className="col-md-6 d-flex flex-column align-items-start">
                  {/* Trial Users */}
                  <div className="mb-2 w-100">
                    <span className="fw-semibold">Trial Users:</span>
                    { filter?.TrailedUser.status
                    ? <span className="badge bg-primary text-white ms-2">From :{moment(filter.TrailedUser.date).format("DD/MM/YYYY")}</span>
                    : <span className="badge bg-primary text-white ms-2"></span>
                    }
                  </div>
                  {/* Source */}
                  <div className="mb-2 w-100">
                    <div className="d-flex flex-wrap gap-2 mt-1">
                    <span className="fw-semibold">Source:</span>
                      {filter.source.map((src, index) => (
                        <span key={index} className="badge bg-warning text-dark">{src}</span>
                      ))}
                    </div>
                  </div>


                  {/* Hubs */}
                  <div className="mb-2 w-100">
                    <div className="d-flex flex-wrap gap-2 mt-1">
                    <span className="fw-semibold">Hub Locations:</span>
                      {filter.hub.map((hub, index) => (
                        <span key={index} className="badge bg-primary text-white">{hub}</span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p className="text-danger">Please select customer status</p>
            )}
          </div>


          {/* Message Section (Footer) */}
          <div className="mb-4 p-3 border rounded shadow-sm" style={{overflowX: "auto"}}>
            <h5 className="fw-bold">
              Message <sup className="text-danger">*</sup>
            </h5>
            <div>
              {selectedValue.length === 0 ? (
                <p className="text-danger">Please select a message type</p>
              ) : (
                <div className="d-flex flex-row gap-2 w-full">
                  {/* {selectedValue.includes("SMS") && (
                    <SmsPreview smsData={msgData?.sms} />
                  )} */}
                  {/* {selectedValue.includes("WhatsApp") && (
                      <WhatsappPreview whatsappData={msgData?.whatsapp} />
                  )} */}

                  {/* {selectedValue.includes("Email") && (
                    <EmailPreview mail={msgData?.email} setMail={()=>{}} />
                  )} */}
                  
                </div>
              )}
            </div>
          </div>

          {/* Customer Details */}
          <div className="mb-4 p-3 border rounded shadow-sm">
            <h5 className="fw-bold mb-3">Customer Details</h5>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    {filter?.status?.length > 0 && <th>Status</th>}
                    {filter?.platform?.length > 0 && <th>Platform</th>}
                    {filter?.subscription_type?.length > 0 && <th>Subscription Type</th>}
                    {filter?.source?.length > 0 && <th>Source</th>}
                    {/* {filter?.wallet && <th>Wallet Balance</th>} */}
                    {filter?.hub?.length > 0 && <th>Hub Location</th>}
                  </tr>
                </thead>
                <tbody>
                  {data && data.length> 0 ? data.map((customer, index) => (
                    <tr
                    key={index}
                    className="align-middle cursor-pointer" 
                    onClick={() => {
                      if (customer.customer_id) {
                        window.open(`/profile/${customer.customer_id}`, "_blank", "noopener,noreferrer");
                      }
                    }}
                  >
                  
                      <td>{customer?.customer_name}</td>
                      <td>{customer?.customer_email}</td>
                      <td>{customer?.customer_phone}</td>
                      {filter?.status?.length > 0 && <td>{customer?.status}</td>}
                      {filter?.platform?.length > 0 && (
                        <td>
                          <span className={`${getBadgeTextClassPlatform(customer?.platform)} ms-2`}>
                        {customer?.platform}</span>
                        </td>
                        )}
                      {filter?.subscription_type?.length > 0 && (
                        <td>
                          {customer?.subscriptionType?.map((type, index) => (
                            <span key={index} className={`${getBadgeClass(type)} ms-2`}>
                              {type}
                            </span>
                          ))}
                        </td>
                      )}
                      {filter?.source?.length > 0 && (
                        <td>
                            <span key={index} className={`${getBadgeTextClass(customer?.source)} ms-2`}>
                              {customer?.source}
                            </span>
                        </td>
                      )}
                      {/* {filter?.wallet && <td>₹{customer?.wallet_balance}</td>} */}
                      {filter?.hub?.length > 0 && <td>{customer?.hub_name}</td>}
                    </tr>
                  ))
                  :
                  <tr>
                    <td className="text-center" colSpan={3+ filter?.status?.length + filter?.platform?.length + filter?.subscription_type?.length + filter?.source?.length + filter?.wallet?.status + filter?.hub?.length}>No customers found</td>
                  </tr>
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
}
      </>
    )
  );
};



// Component 3: New Campaign

const NewCampaign = ({getData}) => {
const { title, selectedValue, isExpand, handleToggleExpand, setSummary, handleDiscard } = useContext(CommunicationContext);
const [loading, setLoading] = useState(false);
// Toast function
const toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});

const handleLaunch = async () => {
  if (title.trim() === "") {
    toast.fire({
      icon: "error",
      title: "Missing Title",
      text: "Please enter a title before proceeding.",
      customClass: {
        popup: "text-danger border-danger",
      },
    });
    return;
  }

  if (selectedValue.length === 0) {
    toast.fire({
      icon: "error",
      title: "No Platform Selected",
      text: "Select at least one platform to continue.",
      customClass: {
        popup: "text-danger border-danger",
      },
    });
    return;
  }

  setLoading(true);

  try {
    await getData(); 
    setSummary(true);
  } catch (error) {
    console.error("Error fetching data:", error);
    toast.fire({
      icon: "error",
      title: "Data Fetch Error",
      html: `
        <div class="text-danger">
          <p>Something went wrong while retrieving data.</p>
          <p>Please try again and review your filter selections.</p>
          <p class="fw-bold">Check the notes for guidance.</p>
        </div>`,
      customClass: {
        popup: "border-danger",
      },
    });
  } finally {
    setLoading(false);
  }
};



// const handleLaunch = async () => {
//   if (title.trim() === "") {
//     toast.fire({
//       icon: "error",
//       title: "No Title",
//       text: "Title is required",
//     });
//     return;
//   }

//   if (selectedValue.length === 0) {
//     toast.fire({
//       icon: "error",
//       title: "No Platform Selected",
//       text: "At least one platform must be chosen",
//     });
//     return;
//   }
//   setLoading(true);

//   try {
//     await getData(); 
//     setSummary(true); 
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   } finally {
//     setLoading(false); 
//   }
// };


return (
  <div className="col-lg-12 grid-margin data-label">
    <div className="card shadow-lg mb-4 bg-light">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3 className="text-primary fw-bold">New Campaign</h3>
          <div className="d-flex">
            
<button 
  type="button" 
  className="btn btn-success btn-sm me-2" 
  onClick={handleLaunch}
  disabled={loading}
>
  {loading ? (
    <>
      <span className="spinner-border spinner-border-sm me-2" role="status" />
      Fetching Customers...
    </>
  ) : (
    "Launch"
  )}
</button>
            <button type="button" className="btn btn-danger btn-sm" onClick={handleDiscard}>
              Discard
            </button>
          </div>
        </div>
        <div className="card shadow-sm mb-4 overflow-hidden">
          <CardHeader toggleExpand={handleToggleExpand} name="Campaign" value={isExpand.Campaign} label="Campaign Details" />
          {isExpand.Campaign && <CampaignDetailsForm />}
        </div>
        <div className="card shadow-sm mb-4 overflow-hidden">
          <CardHeader toggleExpand={handleToggleExpand} name="Filter" value={isExpand.Filter} label="Select Filter" />
          {isExpand.Filter && <FilterBox customerPropertyNames={customerPropertyNames} customerPropertyOptionsMap={customerPropertyOptionsMap} />}
        </div>
        <div className="card shadow-sm mb-4 overflow-hidden">
          <CardHeader toggleExpand={handleToggleExpand} name="Message" value={isExpand.Message} label="Compose Message" />
          {isExpand.Message && <MessageBox />}
        </div>
      </div>
    </div>
  </div>
);
};



export default Communication_Home;


const FilterBox = ({ customerPropertyNames, customerPropertyOptionsMap }) => {
  const { filter, setFilter,  hub, setHub,} = useContext(CommunicationContext);

  
  const getHUbData = async () => {
    try {
      const snapshot = await db.collection("hubs_data").get();
      const data = snapshot.docs.map(doc => doc.data());
      return data;
    } catch (e) {
      // // console.log("fetch hub data error: ", e);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const hubData = await getHUbData();
      // // // console.log(hubData); 
  
      if (hubData && hubData.length > 0) {
        const hubarray = hubData.map(item => ({
          label: item.hub_name,
          value: item.hub_name,
          name: item.hub_name
        }));
        // // // console.log(hubarray); 
        setHub(hubarray); 
      }
    };
  
    fetchData();
  }, []);
  

  
 
  
  return (
    <div className="card-body">
      <div
        className="p-2 mt-2 d-grid gap-3"
        style={{
          gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
        }}
      >
        <TrialUsersFilter />
        <AbandonedCartFilter />
        {/* <WalletFilter /> */}
        {customerPropertyNames &&
          customerPropertyNames.length > 0 &&
          customerPropertyNames.map((item, index) => {
            const options = customerPropertyOptionsMap[item] || [];
            const colSpan = options.length > 5 ? "span 2" : "span 1";
  
            return (
              <div
                key={index}
                style={{
                  gridColumn: colSpan,
                }}
              >
                <CustomFilter
                  property={item}
                  options={options}
                  setFilter={setFilter}
                />
              </div>
            );
          })}
          


        

  
        {hub && hub.length > 0 && (
          <div
            key={-1}
            style={{
              gridColumn: hub.length < 5 ? "span 2" : "span 1",
            }}
          >
            <CustomFilter
              property="hub"
              options={hub}
              setFilter={setFilter}
            />
          </div>
        )}



      </div>
      <div className="alert alert-warning d-flex align-items-start p-3" role="alert">
  <i className="bi bi-exclamation-triangle-fill me-3 mt-1 fs-5 text-warning"></i>
  <div>
    <p className="fw-bold text-primary mb-1">Note:</p>
    <p className="mb-1">
      Selecting too many filters at once may cause Firebase errors. Choose only the necessary filters for better performance.
    </p>
    <p className="mb-0">
      If needed, use <span className="fw-bold text-decoration-underline">Select All</span> instead.
    </p>
  </div>

</div>



    </div>
  );
  
  
  
};


const CustomFilter = ({ property, options, setFilter }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setFilter((prev) => ({ ...prev, [property]: selected }));
  }, [selected]);

  return (
    <div
      className="card p-3 bg-light shadow-sm"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        minWidth: "250px",
      }}
    >
      <CheckboxGroup
        property={property}
        options={options}
        selectedValues={selected}
        onChange={setSelected}
        aling={options && options.length <= 0 ? "column" : "row"}
      />
    </div>
  );
};

const CardHeader = ({ toggleExpand, name, value, label }) => {
  return (
      <div
          className="card-header bg-primary border-bottom d-flex justify-content-between align-items-center px-3"
          onClick={() => toggleExpand(name)}
          style={{ cursor: "pointer" }}
      >
          <h4 className="m-0 py-2 text-light fw-bold">{label}</h4>
          {value ? (
              <FaChevronDown className="text-light" />
          ) : (
              <FaChevronRight className="text-light" />
          )}
      </div>
  );
};




