import db from "../../../Firebase";
import { DateTimeUtil } from "../../../Utility";

class FetchInventory {
  constructor() {
    this.stockDB = "stock_data";
    this.dispatchDB = "dispatch_data";
    this.farmStockDB = "farm_stock_data";
    this.stockHistoryDB = "stock_history";
    this.farmStockHistoryDB = "farm_stock_history";
    
  }

  async fetchStock() {
    try {
      const snapshot = await db.collection(this.stockDB).get();
      return snapshot.docs.map((doc) => doc.data());
    } catch (error) {
      console.error("Error fetching stock data:", error);
      return [];
    }
  }
  async fetchFarmStock() {
    try {
      const snapshot = await db.collection(this.farmStockDB).get();
      return snapshot.docs.map((doc) => doc.data());
    } catch (error) {
      console.error("Error fetching farms stock data:", error);
      return [];
    }
  }

  async fetchStockHistory() {
    try {
      const snapshot = await db
      .collection(this.stockHistoryDB)
      .orderBy("date", "desc")
      // .limit(20)
      .get();
      return snapshot.docs.map((doc) => doc.data());
    } catch (error) {
      console.error("Error fetching stock history data:", error);
      return [];
    }
  }

  async fetchFarmStockHistory() {
    try {
      const snapshot = await db
      .collection(this.farmStockHistoryDB)
      .orderBy("date", "desc")
      // .limit(20)
      .get();
      return snapshot.docs.map((doc) => doc.data());
    } catch (error) {
      console.error("Error fetching stock history data:", error);
      return [];
    }
  }

  async fetchDispatch() {
    try {
      const snapshot = await db.collection(this.dispatchDB)
      .orderBy("date", "desc")
      // .limit(20)
      .get();
      return snapshot.docs.map((doc) => doc.data());
    } catch (error) {
      console.error("Error fetching dispatch data:", error);
      return [];
    }
  }
  async fetchDispatchesByDateRange(date) {
    console.log(date);
    
    // Create a new Date object for the start of the day: 00:00:00
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    // Create a new Date object for the end of the day: 23:59:59
    console.log(startOfDay);
    
    // Create a new Date object for the end of the day: 23:59:59.999
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
    console.log(endOfDay);
    try {
      const snapshot = await db
        .collection(this.dispatchDB)
        .where("date", ">=", startOfDay)
        .where("date", "<=", endOfDay)
        .orderBy("date", "desc") // Order by "dispatch_date" for consistency
        .get();
  
      // Map and return the data
      const data = snapshot.docs.map((doc) => doc.data())
      console.log((data));
      
      return data;
    } catch (error) {
      console.error("Error fetching dispatches by date range:", error);
      return [];
    }
  }
  
}

class AddInventory {
  constructor() {
    this.dispatchDB = "dispatch_data";
    this.stockHistoryDB = "stock_history";
    this.farmStockHistoryDB = "farm_stock_history";
  }

  async addStockHistory(data) {
    const payload = {
      ...data,
      created_date: DateTimeUtil.timestampToISOHyphenDate(new Date()),
      date: new Date(),
    };
    try {
      const response = await db.collection(this.stockHistoryDB).add(payload);
      return { id: response.id, ...payload };
    } catch (error) {
      console.error("Error adding stock history data:", error);
      return null;
    }
  }
  async addFarmStockHistory(data) {
    const payload = {
      ...data,
      created_date: DateTimeUtil.timestampToISOHyphenDate(new Date()),
      date: new Date(),
    };
    try {
      const response = await db.collection(this.farmStockHistoryDB).add(payload);
      return { id: response.id, ...payload };
    } catch (error) {
      console.error("Error adding stock history data:", error);
      return null;
    }
  }

  async addDispatch(data) {
    const payload = {
      ...data,
      created_date: DateTimeUtil.timestampToISOHyphenDate(new Date())
    };
    try {
      const response = await db.collection(this.dispatchDB).add(payload);
      return { id: response.id, ...payload };
    } catch (error) {
      console.error("Error adding dispatch data:", error);
      return null;
    }
  }
}

class UpdateInventory {
  constructor() {
    this.stockDB = "stock_data";
    this.farmStockDB = "farm_stock_data";
    this.dispatchDB = "dispatch_data";
    this.stockHistoryDB = "stock_history";
  }

  validateInput(data, requiredFields) {
    requiredFields.forEach((field) => {
      if (!data[field]) {
        throw new Error(`Validation error: Missing required field '${field}'.`);
      }
    });

    if (data.quantity !== undefined && (isNaN(data.quantity) || data.quantity <= 0)) {
      throw new Error("Validation error: 'quantity' must be a positive number.");
    }
  }

  async updateStock(hub, productId, product, quantity, updateType, user, userId) {
    try {
      this.validateInput({ hub, productId, product, quantity, updateType, user, userId }, ["hub", "productId", "product", "quantity", "updateType", "user", "userId"]);

      const snapshot = await db.collection(this.stockDB)
        .where("hub", "==", hub)
        .where("productId", "==", productId)
        .get();

      if (snapshot.empty) {
        const initialData = {
          hub,
          productName: product,
          productId: productId,
          goodStock: updateType === "Damaged" || updateType === "Out for Delivery" ? 0 : quantity,
          damagedStock: updateType === "Damaged" ? quantity : 0,
          totalStock: quantity,
          quantity_change: quantity,
          update_type: updateType,
          user,
          user_id: userId,
          update_at: new Date(),
          previous_data: null,
        };

        const newDocRef = await db.collection(this.stockDB).add(initialData);
        return { id: newDocRef.id, ...initialData };
      }

      const stockHistory = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      const previousRecord = stockHistory[0];

      const goodStock =
        updateType === "Damaged" || updateType === "Out for Delivery"
          ? (Number(previousRecord.goodStock) || 0) - Number(quantity)
          : (Number(previousRecord.goodStock) || 0) + Number(quantity);

      const damagedStock =
        updateType === "Damaged"
          ? (Number(previousRecord.damagedStock) || 0) + Number(quantity)
          : (Number(previousRecord.damagedStock) || 0)

      if (goodStock < 0) {
        throw new Error("Insufficient stock to complete the update.");
      }

      const totalStock = goodStock + damagedStock;

      const updatedData = {
        hub,
        productName: product,
        productId: productId,
        goodStock,
        damagedStock,
        totalStock,
        quantity_change: quantity,
        update_type: updateType,
        user,
        user_id: userId,
        update_at: new Date(),
        previous_data: previousRecord,
      };

      await db.collection(this.stockDB).doc(previousRecord.id).update(updatedData);
      return updatedData;
    } catch (error) {
      console.error("Error updating stock data:", error);
      return null;
    }
  }
  async updateFarmStock(hub, productId, product, quantity, updateType, user, userId) {
    try {
      this.validateInput({ hub, productId, product, quantity, updateType, user, userId }, ["hub", "productId", "product", "quantity", "updateType", "user", "userId"]);

      const snapshot = await db.collection(this.farmStockDB)
        .where("hub", "==", hub)
        .where("productId", "==", productId)
        .get();

      if (snapshot.empty) {
        const initialData = {
          hub,
          productName: product,
          productId: productId,
          goodStock: updateType === "Damaged" || updateType === "Dispatch" ? 0 : quantity,
          damagedStock: updateType === "Damaged" ? quantity : 0,
          totalStock: quantity,
          quantity_change: quantity,
          update_type: updateType,
          user,
          user_id: userId,
          update_at: new Date(),
          previous_data: null,
        };

        const newDocRef = await db.collection(this.farmStockDB).add(initialData);
        return { id: newDocRef.id, ...initialData };
      }

      const stockHistory = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      const previousRecord = stockHistory[0];

      const goodStock =
        updateType === "Damaged" || updateType === "Dispatch"
          ? (Number(previousRecord.goodStock) || 0) - Number(quantity)
          : (Number(previousRecord.goodStock) || 0) + Number(quantity);

      const damagedStock =
        updateType === "Damaged"
          ? (Number(previousRecord.damagedStock) || 0) + Number(quantity)
          : (Number(previousRecord.damagedStock) || 0)

      if (goodStock < 0) {
        throw new Error("Insufficient stock to complete the update.");
      }

      const totalStock = goodStock + damagedStock;

      const updatedData = {
        hub,
        productName: product,
        productId: productId,
        goodStock,
        damagedStock,
        totalStock,
        quantity_change: quantity,
        update_type: updateType,
        user,
        user_id: userId,
        update_at: new Date(),
        previous_data: previousRecord,
      };

      await db.collection(this.farmStockDB).doc(previousRecord.id).update(updatedData);
      return updatedData;
    } catch (error) {
      console.error("Error updating stock data:", error);
      return null;
    }
  }
  async updateDispatch(dispatch_sub_id, quantity, updatedStatus, user, userId) {
    try {
      this.validateInput({ dispatch_sub_id, quantity, updatedStatus, user, userId }, ["dispatch_sub_id", "quantity", "updatedStatus", "user", "userId"]);

      const snapshot = await db.collection(this.dispatchDB)
        .where("dispatch_sub_id", "==", dispatch_sub_id)
        .get();
        if (snapshot.empty) {
            throw new Error("No dispatch found with the given sub ID.");
        }
      const dispatchData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      const previousRecord = dispatchData[0];
    
      const updatedData = {
        ...previousRecord,
        user,
        user_id: userId,
        update_at: new Date(),
        previous_data: previousRecord,
        quantity,
        status: updatedStatus,
        dispatch_date: updatedStatus === "Dispatched" ? new Date() : null,
      };

      await db.collection(this.dispatchDB).doc(previousRecord.id).update(updatedData);
      return updatedData;
    } catch (error) {
      console.error("Error updating stock data:", error);
      return null;
    }
  }
  async acceptDispatch(dispatch_sub_id, user, userId) {
    try {
      this.validateInput({ dispatch_sub_id, user, userId }, ["dispatch_sub_id", "user", "userId"]);

      const snapshot = await db.collection(this.dispatchDB)
        .where("dispatch_sub_id", "==", dispatch_sub_id)
        .get();
        if (snapshot.empty) {
            throw new Error("No dispatch found with the given sub ID.");
        }
      const dispatchData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      const previousRecord = dispatchData[0];
    
      const updatedData = {
        ...previousRecord,
        accept_user : user,
        accept_user_id: userId,
        accept_at: new Date(),
        status: "Delivered",
      };

      await db.collection(this.dispatchDB).doc(previousRecord.id).update(updatedData);
      return updatedData;
    } catch (error) {
      console.error("Error updating stock data via dispatch:", error);
      return null;
    }
  }
  
}

export default FetchInventory;
export { AddInventory, UpdateInventory };
