import React from "react";
import Dashboard from "./DashBoard";

const Home = () => {
  return (
      <Dashboard />
  );
};

export default Home;
