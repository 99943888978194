import React, { useState, useEffect } from "react";
import moment from "moment";
import TopPanel from "./TopPanel";
import Sidebar from "./Sidebar";
import CustomDateRangePicker from "./components/CustomDateRangePicker";
import Footer from "./Footer";
import { Card } from "react-bootstrap";
import db from "./Firebase";

const CashCollection = () => {
    const [loading, setLoading] = useState(false);
    const [pureData, setPureData] = useState([]);
    const [data, setData] = useState([]);
    const [hubFilter, setHubFilter] = useState("All");
    const [hubs, setHubs] = useState([]);
    const [today] = useState(moment(Date()).format("YYYY-MM-DD"));
    const [dateRange, setDateRange] = useState({ start: today, end: today });

    const handleDateChange = (startDate, endDate) => {
        setDateRange({ start: moment(startDate).format("YYYY-MM-DD"), end: moment(endDate).format("YYYY-MM-DD") });
    };

    useEffect(() => {
        fetchCashCollection();
        setHubFilter("All")
    }, [dateRange]);

    useEffect(() => {
        // Filter data based on selected hub
        if (hubFilter === "All") {
            setData(pureData);
        } else {
            setData(pureData.filter(entry => entry.hub_name === hubFilter));
        }
    }, [hubFilter, pureData]);

    const fetchCashCollection = async () => {
        setLoading(true);
        try {
            let querySnapshot = await db.collection("cash_collection")
                .where("date", ">=", dateRange.start)
                .where("date", "<=", dateRange.end)
                .get();
    
            let data = querySnapshot.docs.map((doc) => doc.data());
    
            data.sort((a, b) => (a.created_date?.seconds || 0) - (b.created_date?.seconds || 0));
    
            data = await Promise.all(
                data.map(async (entry) => {
                    if (entry.hub_name) {
                        return entry;
                    } else {
                        const customerSnapshot = await db.collection("customers_data")
                            .where("customer_id", "==", entry.customer_id)
                            .get();
    
                        const customerData = customerSnapshot.docs[0]?.data() || {};
                        return { ...entry, hub_name: customerData.hub_name || "Unknown" };
                    }
                })
            );
    
            setPureData(data);
            setHubs([...new Set(data.map(entry => entry.hub_name))]); // Extract unique hub names
        } catch (error) {
            console.error("Error fetching cash collection:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
        {loading && (
            <div className="loader-overlay">
                <div>
                    <img alt="loader" style={{ height: "6rem" }} src="images/loader.gif" />
                </div>
            </div>
        )}
        <div className="content-wrapper">
    <div className="home-tab d-flex justify-content-between align-items-center my-3 flex-wrap">
        <h3 className="fw-bold text-primary border-bottom pb-2 mb-0">Cash Collection</h3>

        <div className="d-flex w-100 justify-content-between flex-wrap gap-3 mt-2">
            {/* Left: Hub Filter (Label & Select in the same row) */}
            <div className="d-flex align-items-start gap-2">
                <label className="fw-bold fs-3">Hub:</label>
                <select
                    className="form-select"
                    value={hubFilter}
                    onChange={(e) => setHubFilter(e.target.value)}
                    style={{ minWidth: "200px" }}
                >
                    <option value="All">All Hubs</option>
                    {hubs.map((hub, index) => (
                        <option key={index} value={hub}>
                            {hub}
                        </option>
                    ))}
                </select>
            </div>

            {/* Right: Export & Date Picker (Stacked in a column) */}
            <div className="d-flex flex-column gap-1 align-items-end">
                <ExportCSV data={data} />
                <CustomDateRangePicker onDateChange={handleDateChange} />
            </div>
        </div>
    </div>

    <Table data={data} />
</div>

    </>
    
    );
};

const ExportCSV = ({ data }) => {
    const exportToCSV = () => {
        if (data.length === 0) {
            alert("No data available to export.");
            return;
        }

        const headers = ["S.No.", "Customer ID", "Customer Name", "Customer Phone", "Amount", "Collection Date", "Created At", "Status", "Hub", "Cash Collector Name", "Customer Address"];
        const csvRows = [headers.join(",")];

        data.forEach((val, index) => {
            const row = [
                index + 1,
                val.customer_id,
                val.customer_name,
                val.customer_phone,
                val.amount,
                moment(val.date, "YYYY-MM-DD").format("DD-MM-YYYY"),
                val.created_date ? moment(val.created_date.seconds * 1000).format("DD-MM-YYYY") : "",
                val.status === "1" ? "Collected" : "Pending",
                val.hub_name,
                val.delivery_executive_name,
                val.customer_address
            ].map(value => `"${String(value).replace(/"/g, '""')}"`);

            csvRows.push(row.join(","));
        });

        const csvContent = "data:text/csv;charset=utf-8," + csvRows.join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Cash_Collection_Report.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return <button className="btn btn-primary ms-3" onClick={exportToCSV} disabled={data.length === 0}>Export to CSV</button>;
};

const Table = ({ data }) => {
    return (
        <div className="table-responsive">
            <table className="table table-striped table-bordered">
                <thead className="table-dark">
                    <tr>
                        {["S.No.", "Customer ID", "Customer Name", "Customer Phone", "Amount", "Collection Date", "Created At", "Status", "Hub", "Cash Collector Name", "Customer Address"].map((col, index) => (
                            <th key={index} className="text-center">{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? data.map((val, index) => (
                        <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{val.customer_id}</td>
                            <td className="text-center">{val.customer_name}</td>
                            <td className="text-center">{val.customer_phone}</td>
                            <td className="text-center">{val.amount}</td>
                            <td className="text-center">{moment(val.date).format("DD-MM-YYYY")}</td>
                            <td className="text-center">{val.created_date ? moment(val.created_date.seconds * 1000).format("DD-MM-YYYY") : ""}</td>
                            <td className={`text-center fw-bold ${val.status === "1" ? "text-success" : "text-danger"}`}>{val.status === "1" ? "Collected" : "Pending"}</td>
                            <td className="text-center">{val.hub_name}</td>
                            <td className="text-center">{val.delivery_executive_name}</td>
                            <td className="text-center">{val.customer_address}</td>
                        </tr>
                    )) : <tr><td colSpan="11" className="text-center fw-bold text-danger">NO DATA FOUND</td></tr>}
                </tbody>
            </table>
        </div>
    );
};

export default CashCollection;
