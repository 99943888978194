import React, { useEffect, useState } from "react";
import db from "../../Firebase";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import ExportOrderHistory from "./ExportOrderHistory";
import {
  FaArrowLeft,
  FaArrowRight,
  FaCoffee,
  FaEdit,
  FaMapMarkerAlt,
  FaSave,
  FaWarehouse,
} from "react-icons/fa";
import Swal from "sweetalert2";
import DateInput from "../../components/Date";
import { SelectionState } from "draft-js";
import PDFComponent from "./Degital Challan/PdfGenerator";

const ViewCafeOrders = ({ rowData}) => {
  const [loading, setLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [dateRange, setDateRange] = useState({
    start: new Date(),
    end: new Date(),
  });
  

  const handleDateChange = (startDate, endDate) => {
    setDateRange({ start: startDate, end: endDate });
  };

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      console.error("Input must be a Date object", date); 
      return null; 
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const fetchOrderHistory = async () => {
    setLoading(true);
    if(!rowData || !(rowData?.type) ||!dateRange || !(dateRange?.start) ){
      return
    }
    try {
      let query = db.collection("b2b_orders")
          query = query.where("hub_name", "==", rowData.delivery_hub)
          query = query.where("order_type", "==", rowData.type);
        if(rowData.type === "Cafe"){
          query = query.where("location", "==", rowData.cafe_location)
        }
        if (dateRange.start) {
          query = query.where(
            "delivery_date",
            ">=",
            formatDate(dateRange.start)
          );
        }
        if (dateRange.end) {
          query = query.where(
            "delivery_date",
            "<=",
            formatDate(dateRange.end)
          );
      }

      const snapshot = await query.get();
      const orders = snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setOrderList(orders);
      console.log(orders);
    } catch (error) {
      console.error("Error fetching orders: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(dateRange)
    if (rowData) {
      fetchOrderHistory();
    }
  }, [dateRange, rowData]);
  
  useEffect(() => {
    return () => {
      setDateRange({start: null, end: null});
      };
  }, []);

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="">
            <img
              alt="loader"
              style={{
                height: "6rem",
              }}
              src="images/loader.gif"
            ></img>
          </div>
        </div>
      )}
      <OrderHistoryHeader
        rowData={rowData}
        handleDateChange={handleDateChange}
        orderList={orderList} fetchOrderHistory={fetchOrderHistory}
        dateRange={dateRange}
      />
      <OrderHistoryTable rowData={rowData} orderList={orderList} fetchOrderHistory={fetchOrderHistory}/>
    </>
  );
};

export default ViewCafeOrders;

const OrderHistoryHeader = ({ rowData, handleDateChange, orderList, fetchOrderHistory, dateRange }) => {
  return (
    <div className="container-fluid d-flex flex-column align-items-center justify-content-center p-4">
      <div className="d-flex justify-content-between align-items-center w-100 mb-3">
        <div className="d-flex align-items-center">
          <FaCoffee className="me-2" size={24} />
          <h5 className="modal-title" id="exampleModalLabel">
            Cafe: {rowData.cafe_name}
          </h5>
        </div>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          data-bs-dismiss="modal"
        ></button>
      </div>
      <div className="d-flex justify-content-between align-items-center w-100 mb-3">
        <div className="d-flex align-items-center">
          <FaWarehouse className="me-2" size={24} />
          <h5 className="modal-title" id="exampleModalLabel">
            Hub: {rowData.delivery_hub}
          </h5>
        </div>
        <ExportOrderHistory orderList={orderList} fetchOrderHistory={fetchOrderHistory} dateRange={dateRange} rowData={rowData}/>
      </div>
      <div className="d-flex justify-content-between align-items-start w-100 mb-3">
        <div className="d-flex align-items-start">
          <FaMapMarkerAlt className="me-2" size={24} />
          <h5 className="modal-title" id="exampleModalLabel">
            Location: {rowData.cafe_location}
          </h5>
        </div>
        <CustomDateRangePicker onDateChange={handleDateChange} />
      </div>
      <div className="d-flex justify-content-between align-items-start w-100 mb-3">
        <div className="d-flex align-items-start">
          
        </div>
        <div className="d-flex align-items-start">
        </div>
      </div>
    </div>
  );
};



const OrderHistoryTable = ({ rowData, orderList, fetchOrderHistory }) => {
  return (
    <>
      <div className="modal-body">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Order Date</th>
                <th style={{ textAlign: "center" }}>Product Name</th>
                <th style={{ textAlign: "center" }}>Quantity</th>
                <th style={{ textAlign: "center" }}>Package Unit</th>
                <th style={{ textAlign: "center" }}>Location</th>
                <th style={{ textAlign: "center" }}>Created by</th>
                <th style={{ textAlign: "center" }}>Status</th>
                <th style={{ textAlign: "center" }}>Action</th>
                <th style={{ textAlign: "center" }}>DC</th>
                <th style={{ textAlign: "center" }}>Created Date</th>
                <th style={{ textAlign: "center" }}>Update By</th>
                <th style={{ textAlign: "center" }}>Update Date</th>
                <th style={{ textAlign: "center" }}>Previous Quantity</th>
              </tr>
            </thead>
            <tbody>
              {orderList && orderList.length > 0 ? (
                orderList.map(({ id, data }) => (
                  <HistoryRow key={id} id={id} data={data} fetchOrderHistory={fetchOrderHistory} rowData={rowData}/>
                ))
              ) : (
                <tr>
                  <td colSpan="13" style={{ textAlign: "center" }}>
                    No orders found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};



const HistoryRow = ({ id, data, fetchOrderHistory, rowData }) => {
  const [edit, setEdit] = useState(false);
  const [quantity, setQuantity] = useState(data.quantity);
  const [date, setDate] = useState((data.delivery_date && !(data.delivery_date instanceof Date) || isNaN(data.delivery_date)) ? new Date(data.delivery_date) : "Invalid Date");
  const [status, setStatus] = useState(data.status);

  const Toast = Swal.mixin({
    toast: true,
    background: '#d7e7e6',
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  const handleEdit = () => setEdit(true);

  const handleSave = async () => {
    const user = localStorage.getItem("loggedIn_user");
    const userId = localStorage.getItem("userId");

    const orderDetails = {
      ...data,
      status,
      delivery_date: date ? new Date(date).toISOString().split('T')[0] : null,
      quantity,
      updated_by: user,
      updated_by_user_id: userId,
      updated_date: new Date().toISOString().split('T')[0],
      updated_at: new Date(),
      previous_quantity: data?.previous_quantity ? [data.quantity, ...data.previous_quantity] : [data.quantity],
    };

    try {
      const orderRef = db.collection("b2b_orders").doc(id);
      await orderRef.update(orderDetails);
      setEdit(false);
      Toast.fire({
        icon: 'success',
        title: `Order ID: ${data.order_id} updated successfully`
      });
    } catch (e) {
      console.error("Order update error: ", e);
      Toast.fire({
        icon: 'error',
        title: `Order ID: ${data.order_id} updated unsuccessfully`
      });
    } finally {
      fetchOrderHistory();
    }
  };

  const handleDelete = async () => {
    try {
      const orderRef = db.collection("b2b_orders").doc(id);
      await orderRef.delete();
      Toast.fire({
        icon: 'success',
        title: `Order ID: ${data?.order_id} deleted successfully`
      });
    } catch (e) {
      console.error("Order delete error: ", e);
      Toast.fire({
        icon: 'error',
        title: `Order ID: ${data?.order_id} deleted unsuccessfully`
      });
    } finally {
      await fetchOrderHistory();
    }
  };

  const formatDate = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      return "Invalid Date"; 
    }
    
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    
    return `${day}/${month}/${year}`;
  };

  const statusOptions = [
    { value: 0, label: "Pending", class: "badge bg-warning text-dark" },
    { value: 1, label: "Delivered", class: "badge bg-success text-white" },
    { value: 2, label: "Cancelled", class: "badge bg-danger text-white" },
  ];
  const [currentStatus, setCurrentStatus] = useState();
  useEffect(()=>{
    const currentStatus = statusOptions.find(option => option.value === status);
    setCurrentStatus(currentStatus);
  }, [status])
  
  useEffect(()=>{
    setEdit(false);
    return ()=>{
      setEdit(false);
    }
  }, [data]);
  return (
    <tr className="hover-highlight">
      <td style={{  verticalAlign: "top" }}>
        {/* {
        edit ? (
          <DateInput date={date} setDate={setDate} />
        ) : (
          formatDate(new Date(data.delivery_date))
        )} */}
        {formatDate(new Date(data.delivery_date))}
      </td>
      <td style={{  verticalAlign: "top" }}>{data.product_name}</td>
      <td style={{  verticalAlign: "top" }}>
        {edit ? (
          <input
            className="form-control p-2"
            type="number"
            onChange={(e) => setQuantity(e.target.value)}
            value={quantity}
            required
            autoComplete="off"
            min="1"
            step="1"
          />
        ) : (
          data.quantity
        )}
      </td>
      <td style={{  verticalAlign: "top" }}>{data.package_unit}</td>
      <td style={{  verticalAlign: "top" }}>{data.hub_name}</td>
      <td style={{  verticalAlign: "top" }}>{data.created_by}</td>
      <td style={{  verticalAlign: "top" }}>
        {edit && data.status === 0 ? (
        //   <button
        //     className={status === 0 ? "badge bg-warning text-dark" : status === 1 ? "badge bg-success text-white" : "badge bg-danger text-white"}
        //     onClick={() => setStatus((prev) => (prev === 2 ? 0 : prev+1))}
        //     >
        //     {status === 0 ? "Pending" : status === 1 ? "Delivered" : "Cancelled"}
        //   </button>
        // ) : (
        //   <span className={status === 0 ? "badge bg-warning text-dark" : status === 1 ? "badge bg-success text-white" : "badge bg-danger text-white"} >
        //     {status === 0 ? "Pending" : status === 1 ? "Delivered" : "Cancelled"}
        //   </span>
          <button
            className={currentStatus?.class}
            onClick={() => setStatus((prev) => (prev === 2 ? 0 : prev + 1))}
          >
            {currentStatus?.label}
          </button>
        ) : (
          <span className={currentStatus?.class}>
            {currentStatus?.label}
          </span>
        
        )}
      </td>
      <td style={{  verticalAlign: "top" }}>
        {edit ? (
          <button
            className="btn btn-dark btn-sm"
            style={{
              backgroundColor: "blue",
              border: "none",
              marginRight: "1rem",
              padding: "0.2rem 0.85rem",
            }}
            onClick={handleSave}
          >
            <FaSave style={{ color: "white" }} />
          </button>
        ) : 
          status === 0 ? (
          <>
            <button
              className="btn btn-dark btn-sm"
              style={{
                marginRight: "1rem",
                padding: "0.2rem 0.85rem",
              }}
              onClick={handleEdit}
            >
              <FaEdit style={{ color: "white" }} />
            </button>
          </>) :
          <p>-</p>
            
        }
      </td>
      <td style={{  verticalAlign: "top" }}>
        <PDFComponent order={[data]} cafeData={rowData}/>
      </td>
      <td style={{  verticalAlign: "top" }}>
        {formatDate(new Date(data.created_date))}
      </td>
      <td style={{  verticalAlign: "top" }}>{data.updated_by || "N/A"}</td>
      <td style={{  verticalAlign: "top" }}>
        {data.updated_date ? formatDate(new Date(data.updated_date)) : "N/A"}
      </td>
      <td style={{ verticalAlign: "top" }} title="Previous quantity (newest to oldest)">
  {data.previous_quantity?.length 
    ? data.previous_quantity.map((item, index) => (
      <span>

        <span className="p-1" key={index}>
          {item} 
        </span>
          {index != data.previous_quantity.length -1 ? <span className="px-1"><FaArrowLeft /></span> : null}
      </span>
      )) 
    : "N/A"}
</td>



    </tr>
  );
};

