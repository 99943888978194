import DatePicker from 'react-datepicker';
import { FaCompressAlt, FaExpandAlt } from "react-icons/fa";
import React, { useState, useEffect,useContext } from "react";
import './UTMReport.css'
import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
  limit,
  
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
// import './subscripation.css';
import { Button, Alert, Spinner } from "react-bootstrap"; // Import Spinner for loader
import moment from "moment"; // Import moment for date formatting
import Select from "react-select"; // Import Select for multi-select
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import db from "../../../Firebase";
import { handleLogout } from "../../../Utility";
import GlobalContext from "../../../context/GlobalContext";

const UTMReport = () => {
  const {permissible_roles} = useContext(GlobalContext);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedHub, setSelectedHub] = useState([]);
 
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedSubscription, setSelectedSubscription] =
    useState([]);
  const [customerNumber, setCustomerNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [data, setData] = useState([]);
  const [hubOptions, setHubOptions] = useState([]);
  const [showNoDataFound, setShowNoDataFound] = useState(false);
  const [loading, setLoading] = useState(false); 


  const [selectedTrail, setSelectedTrail] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  
  const [utmSourceOptions, setUtmSourceOptions] = useState([]);
  const [selectedUtmSource, setSelectedUtmSource] = useState([]);

  const statusOptions = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ];


  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleExpand = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsFullScreen(true);
      setIsLoading(false); // Stop loading after action completes
    }, 500); // Simulating a delay for demonstration purposes
  };

  const handleCollapse = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsFullScreen(false);
      setIsLoading(false); // Stop loading after action completes
    }, 500); // Simulating a delay for demonstration purposes
  };


  const handleRowClick = (customer_id) => {
    window.open(`/profile/${customer_id}`, "_blank");
  };
/** Fetch unique utm_source */
// const fetchUtmSources = async () => {
//   try {
//     let sources = []; // Array to store unique sources
//     let lastVisible = null; // Keeps track of the last document
//     let hasMore = true; // Flag to control whether more data is available

//     // Fetch the first document to initialize the query
//     const initialSnapshot = await db.collection("customers_data")
//       .where("source", "!=", "")
//       .limit(1)
//       .get();

//     // If there are documents in the initial snapshot, add the first one to sources
//     if (!initialSnapshot.empty) {
//       const initialSource = initialSnapshot.docs[0].data();
//       console.log(initialSource);
//       sources.push(initialSource.source); // Add the initial source to the array
//       lastVisible = initialSnapshot.docs[0]; // Set the last visible document
//     }

//     // Keep fetching additional sources using startAfter
//     while (hasMore) {
//       // Query for sources starting after the last document in the previous query
//       const snapshot = await db.collection("customers_data")
//         .where("source", "not-in", sources) // Exclude the already collected sources
//         .startAfter(lastVisible) // Continue from the last document
//         .limit(1) // Fetch one document at a time
//         .get();

//       if (!snapshot.empty && sources.length !== 10) {
//         const newSource = snapshot.docs[0].data().source; // Get the source from the document
//         if (newSource && !sources.includes(newSource)) {
//           sources.push(newSource); // Add new unique source to the array
//         }
//         lastVisible = snapshot.docs[0]; // Update the last visible document
//       } else {
//         hasMore = false; // No more documents, stop the loop
//       }
//     }

//     // Map sources to dropdown-compatible objects
//     const sourceOptions = sources.map((item) => ({
//       value: item,
//       label: item,
//     }));

//     console.log("All UTM sources:", sourceOptions); // Optionally log the final sources

//     // Update state with the fetched options
//     setUtmSourceOptions(sourceOptions);
//   } catch (e) {
//     console.log("Error fetching UTM sources:", e);
//   }
// };




  useEffect(() => {
    const fetchOptions = async () => {
      try {
        // Fetch hubs
        const hubQuerySnapshot = await getDocs(collection(db, "hubs_data"));
        const hubOptions = hubQuerySnapshot.docs.map((doc) => ({
          value: doc.data().hub_name,
          label: doc.data().hub_name,
        }));
        setHubOptions(hubOptions);

        // Fetch products
        const productQuerySnapshot = await getDocs(
          collection(db, "products_data")
        );
        const productOptions = productQuerySnapshot.docs.map((doc) => ({
          value: doc.data().productName,
          label: doc.data().productName,
        }));
        setProductOptions(productOptions);
        /** utm_source */
       const utmSourceQuerySnapshot = await getDocs(
        query(
          collection(db, "subscriptions_data"),
          where("utm_source", "!=", null) 
        )
      );
      utmSourceQuerySnapshot.forEach((doc) => {
        console.log(doc.data().utm_source);
      });
       const uniqueUtmSourceOptions = utmSourceQuerySnapshot.docs
         .map((doc) => doc.data().utm_source)
         .filter((value, index, self) => value && self.indexOf(value) === index)
         .map((utm_source) => ({ value: utm_source, label: utm_source }));
       setUtmSourceOptions(uniqueUtmSourceOptions);
        
        
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    if (!loggedIn) {
      navigate("/login");
  }else{
      if(permissible_roles.length>0){
          if(!permissible_roles.includes('subscription_report')){
              handleLogout()
              navigate("/permission_denied");
          }
      }
  }
  }, [navigate,permissible_roles]);



  const handleSearch = async () => {
    if (endDate && startDate && endDate < startDate) {
      alert("End date cannot be earlier than start date.");
      return;
    }
  
    setLoading(true);
  
    const subscriptionsRef = collection(db, "customers_data");
    let filters = [];
  
    try {
      // Construct filters safely
      if (selectedHub.length > 0) {
        const hubValues = selectedHub.map((h) => h.value).filter(Boolean);
        if (hubValues.length > 0) {
          filters.push(where("hub_name", "in", hubValues));
        }
      }
  
      if (selectedUtmSource.length > 0) {
        const utmSourceValues = selectedUtmSource.map((u) => u.value).filter(Boolean);
        if (utmSourceValues.length > 0) {
          filters.push(where("source", "in", utmSourceValues));
        }
      }
  
      if (startDate) {
        filters.push(where("created_date", ">=", Timestamp.fromDate(startDate)));
      }
  
      if (endDate) {
        filters.push(where("created_date", "<=", Timestamp.fromDate(endDate)));
      }
  
      console.log("Constructed Filters:", filters);
  
      // Query Firestore for customers_data
      const q = filters.length > 0 ? query(subscriptionsRef, ...filters) : query(subscriptionsRef, limit(1000));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        setShowNoDataFound(true);
        setData([]);
      } else {
        const customerData = querySnapshot.docs.map((doc) => doc.data());
        setData(customerData);
  
        // Query subscriptions_data for matching customer_ids
        const customer_ids = customerData.map(({ customer_id }) => customer_id);
        if (customer_ids.length > 0) {
          const subscriptionsRef1 = collection(db, "subscriptions_data");
  
          // Fetch in batches of 30
          const batchSize = 30;
          let batchPromises = [];
  
          for (let i = 0; i < customer_ids.length; i += batchSize) {
            const batchCustomerIds = customer_ids.slice(i, i + batchSize);
            const q1 = query(subscriptionsRef1, where("customer_id", "in", batchCustomerIds));
            const querySnapshot1 = getDocs(q1);
            batchPromises.push(querySnapshot1);
          }
  
          // Wait for all batches to complete
          const batchResults = await Promise.all(batchPromises);
  
          // Collect all the results from each batch
          let subscriptionData1 = [];
          batchResults.forEach((snapshot) => {
            if (!snapshot.empty) {
              subscriptionData1 = [...subscriptionData1, ...snapshot.docs.map((doc) => doc.data())];
            }
          });
  
          const joinedData = customerData.map((customer) => {
            const subscription = subscriptionData1.find(
              (sub) => sub.customer_id === customer.customer_id
            );
            return {
              ...customer,
              subscribed: subscription ? true : false,
              trial: subscription ? true : false,
            };
          });
  
          console.log("Joined Data:", joinedData);
          setData(joinedData);
  
          // Filter customers based on trial status if selected
          let filteredData = joinedData;


          // Filter customers based on subscription status if selected
          if (selectedSubscription.length === 1) {
            filteredData = filteredData.filter(({ subscribed }) => {
              if (selectedSubscription[0].label === "Yes" && subscribed) return true;
              if (selectedSubscription[0].label === "No" && !subscribed) return true;
              return false;
            });
          }
  
          if (selectedTrail.length === 1) {
            filteredData = filteredData.filter(({ trial }) => {
              if (selectedTrail[0].label === "Yes" && trial) return true;
              if (selectedTrail[0].label === "No" && !trial) return true;
              return false;
            });
          }
          console.log(selectedTrail);
          
          console.log(selectedSubscription);
  
          console.log("Filtered Data:", filteredData);
          setData(filteredData);
        } else {
          setShowNoDataFound(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("An error occurred while fetching the data. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  
  
  
  const handleSearch1 = async () => {
    if (endDate && startDate && endDate < startDate) {
      alert("End date cannot be earlier than start date.");
      return;
    }
  
    setLoading(true);
  
    const subscriptionsRef = collection(db, "customers_data");
    let filters = [];
  
    try {
      // Construct filters safely
      // if (selectedProduct.length > 0) {
      //   const productValues = selectedProduct.map((p) => p.value).filter(Boolean); // Filter out undefined/null
      //   if (productValues.length > 0) {
      //     filters.push(where("product_name", "in", productValues));
      //   }
      // }
  
      if (selectedHub.length > 0) {
        const hubValues = selectedHub.map((h) => h.value).filter(Boolean);
        if (hubValues.length > 0) {
          filters.push(where("hub_name", "in", hubValues));
        }
      }
      if (selectedUtmSource.length > 0) {
        const utmSourceValues = selectedUtmSource.map((u) => u.value).filter(Boolean);
        if (utmSourceValues.length > 0) {
          filters.push(where("source", "in", utmSourceValues));
        }
      }
      
  
      if (startDate) {
        filters.push(where("created_date", ">=", Timestamp.fromDate(startDate)));
      }
  
      if (endDate) {
        filters.push(where("created_date", "<=", Timestamp.fromDate(endDate)));
      }
  
      // if (selectedStatus) {
      //   filters.push(where("status", "==", selectedStatus.value));
      // }
  
      // if (selectedSubscription) {
      //   filters.push(where("subscription_type", "==", selectedSubscription.value));
      // }
  
      console.log("Constructed Filters:", filters);
  
      // Query Firestore
      const q = filters.length > 0 ? query(subscriptionsRef, ...filters) : subscriptionsRef;
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        setShowNoDataFound(true);
        setData([]);
      } else {
        const subscriptionData = querySnapshot.docs.map((doc) => doc.data());
        setData(subscriptionData);
        
      
        const customer_ids = subscriptionData.map(({customer_id})=>customer_id)
      const subscriptionsRef1 = collection(db, "subscriptions_data");
      const q1 = query(subscriptionsRef1, where("customer_id", "in", customer_ids))
      const querySnapshot1 = await getDocs(q1);
      if (querySnapshot1.empty) {
        setShowNoDataFound(true);
        setData([]);
      } else {
        const subscriptionData1 = querySnapshot1.docs.map((doc) => doc.data());
        console.log(subscriptionData1);
      }
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  
  
  

  const handleReset = () => {
    setCustomerNumber("");
    setCustomerName("");
    setStartDate(null);
    setEndDate(null);
    setSelectedHub([]);
    setSelectedProduct([]);
    setSelectedUtmSource([]);
    setSelectedTrail([])
    setSelectedStatus(null);
    setSelectedSubscription(null);
    setData([]);
  };
  const exportTableToPDF = () => {
    const doc = new jsPDF();
    doc.text("Customer Report", 20, 20);
  
    const tableColumn = [
      "Customer ID",
      "Customer Name",
      "Source",
      "Hub",
      "Trial",
      "Subscribe",
      "Register on",
    ];
  
    const tableRows = data.map((item) => [
      item.customer_id,
      item.customer_name || "N/A",
      item.source || "N/A",
      item.hub_name || "N/A",
      item.trial ? "Yes" : "No",
      item.subscribed ? "Yes" : "No",
      item.created_date
        ? moment(item.created_date.toDate()).format("DD/MM/YYYY")
        : "-",
    ]);
  
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
    });
  
    doc.save("customer_report.pdf");
  };
  
  
  const exportTableToCSV = () => {
    /* Prepare CSV headers */
    const csvColumns = [
      "Customer ID",
      "Customer Name",
      "Source",
      "Hub",
      "Trial",
      "Subscribe",
      "Register on",
    ];
  
    /* Function to escape special characters in CSV data */
    const escapeCSVValue = (value) => {
      if (!value) return '"N/A"';
      const stringValue = String(value).replace(/"/g, '""');
      return stringValue.includes(",") || stringValue.includes("\n") || stringValue.includes('"')
        ? `"${stringValue}"`
        : stringValue;
    };
  
    /* Prepare CSV rows */
    const csvRows = data.map((item) => [
      escapeCSVValue(item.customer_id),
      escapeCSVValue(item.customer_name || "N/A"),
      escapeCSVValue(item.source || "N/A"),
      escapeCSVValue(item.hub_name || "N/A"),
      item.trial ? "Yes" : "No",
      item.subscribed ? "Yes" : "No",
      item.created_date
        ? moment(item.created_date.toDate()).format("DD/MM/YYYY")
        : "-",
    ]);
  
    // Combine headers and rows into a single CSV content
    const csvContent = [
      csvColumns.join(","),
      ...csvRows.map((row) => row.join(",")),
    ].join("\n");
  
    /* Create a Blob from the CSV content */
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  
    /* Create a link element for downloading */
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "customer_report.csv");
    link.style.visibility = "hidden";
  
    /* Append the link to the body */
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const exportTableToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      data.map((item) => ({
        "Customer ID": item.customer_id,
        "Customer Name": item.customer_name || "N/A",
        Source: item.source || "N/A",
        Hub: item.hub_name || "N/A",
        Trial: item.trial ? "Yes" : "No",
        Subscribe: item.subscribed ? "Yes" : "No",
        "Register on": item.created_date
          ? moment(item.created_date.toDate()).format("DD/MM/YYYY")
          : "-",
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Customers");
    XLSX.writeFile(wb, "customer_report.xlsx");
  };


  return (
    <>
      
      <div className="card p-2 mb-4" style={{ position: "relative" }}>
        <div
          className="panel p-1"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative"
          }}
        >
          <span
            style={{
            fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px"
            }}
          >
            SUBSCRIPTION REPORT
          </span>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={exportTableToPDF}
              disabled={loading}
              className="btn btn-success btn-rounded btn-sm"
            >
              Export PDF
            </Button>
            <Button
              onClick={exportTableToExcel}
              disabled={loading}
              className="btn btn-success btn-rounded btn-sm"
            >
              Export Excel
            </Button>
            <Button
              onClick={exportTableToCSV}
              disabled={loading}
              className="btn btn-success btn-rounded btn-sm"
            > Export CSV
            </Button>
          </div>
        </div>
      </div>
      <div className="card p-1 mb-4">
        <div className="container my-2"> 
          <div className="p-3">
            <div className="row g-3">
              
              {/* UTM Source */}
              <div className="col-12 col-md-6 col-lg-4">
                <CustomSelect
                  label="Utm Source:"
                  options={utmSourceOptions}
                  value={selectedUtmSource}
                  onChange={(selected) => setSelectedUtmSource(selected)}
                  placeholder="Select UTM Source(s)"
                  minWidth="200px"
                  maxWidth="300px"
                />
              </div>

              {/* Hub */}
              <div className="col-12 col-md-6 col-lg-4">
                <CustomSelect
                  label="Hub:"
                  options={hubOptions}
                  onChange={(selected) => setSelectedHub(selected)}
                  placeholder="Select hub(s)"
                  minWidth="200px"
                  maxWidth="300px"
                />
              </div>

              {/* Subscried */}
              <div className="col-12 col-md-6 col-lg-4">
                <CustomSelect
                  label="Subscried:"
                  options={["Yes", "No"].map((trail) => ({ value: trail, label: trail }))}
                  onChange={(selected) => setSelectedSubscription(selected)}
                  placeholder="Select subscried"
                  minWidth="200px"
                  maxWidth="300px"
                />
              </div>

              {/* Trail */}
              <div className="col-12 col-md-6 col-lg-4">
                <CustomSelect
                  label="Trail:"
                  options={["Yes", "No"].map((trail) => ({ value: trail, label: trail }))}
                  onChange={(selected) => {setSelectedTrail(selected)}}
                  placeholder="Select Trail(s)"
                  minWidth="200px"
                  maxWidth="300px"
                />
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <CustomDatePicker
                  label="Start Date:"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholder="Select start date"
                  minWidth="200px"
                  maxWidth="300px"
                />
              </div>

              {/* End Date */}
              <div className="col-12 col-md-6 col-lg-4">
                <CustomDatePicker
                  label="End Date:"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholder="Select end date"
                  minWidth="200px"
                  maxWidth="300px"
                />
              </div>


              <div className="col-12 col-md-6 col-lg-4">
                <div className="d-flex justify-content-start gap-3 mt-3">
                  <Button
                    variant="outline-success"
                    onClick={handleSearch}
                    disabled={loading}
                    className="d-flex align-items-center"
                  >
                    {loading ? <Spinner animation="border" size="sm" /> : "Search"}
                  </Button>
                  <Button
                    variant="outline-danger"
                    onClick={handleReset}
                    className="d-flex align-items-center"
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
      <div className={`card p-3 ${isFullScreen ? 'fullscreen' : 'mb-4'}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-start p-2">
            <h5 className="text-primary mb-0">Stock Levels</h5>
            <div className="d-flex flex-column justify-content-center align-items-end gap-1">
              <button
                onClick={isFullScreen ? handleCollapse : handleExpand}
                style={{
                  background: 'none',
                  border: 'none',
                  fontSize: '20px',
                  padding: '5px 10px',
                  cursor: 'pointer',
                }}
              >
                {isLoading ? (
                  <Spinner animation="border" size="sm" /> // Show loader when in progress
                ) : (
                  isFullScreen ? <FaCompressAlt /> : <FaExpandAlt />
                )}
              </button>
              <h6>Count : {data.length || 0}</h6>
            </div>
          </div>

          <div className={` p-2 ${isFullScreen ? 'fullscreen-table' : ''}`} style={{ overflowY: 'auto', overflowX: 'auto'}}>
            <table className="table table-striped" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Customer ID</th>
                  <th>Customer Name</th>
                  <th>Source</th>
                  <th>Hub</th>
                  <th>Trial</th>
                  <th>Subscribe</th>
                  <th>Register on</th>
                </tr>
              </thead>
              <tbody>
                {data.length ? (
                  data.map((item, index) => (
                    <tr key={index} 
                    className="hover-highlight"
                    style={{cursor: "pointer"}}
                    onClick={() => handleRowClick(item.customer_id)}
                    >
                      <td>{item.customer_id}</td>
                      <td>{item.customer_name || 'N/A'}</td>
                      <td>{item.source}</td>
                      <td>{item.hub_name || 'N/A'}</td>
                      <td>{item.trial ? 'Yes' : 'No'}</td>
                      <td>{item.subscribed ? "Yes": "No"}</td>
                      <td>{item.created_date ? moment(item.created_date.toDate()).format('DD/MM/YYYY') : '-'}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      {showNoDataFound ? 'No data found for the given filters.' : 'No data available.'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  );
};

export default UTMReport;



const CustomSelect = ({
  label,
  options,
  value,
  onChange,
  placeholder,
  minWidth = "200px",
  maxWidth = "300px",
}) => {
  return (
    <div style={{ flex: "1", minWidth, maxWidth }}>
      <label style={{ fontWeight: "600", marginBottom: "5px", display: "block" }}>
        {label}
      </label>
      <Select
        isMulti
        options={options}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        styles={{
          container: (provided) => ({ ...provided, width: "100%" }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          // indicatorsContainer: () => ({ display: "none" }),
        }}
      />
    </div>
  );
};

const CustomDatePicker = ({
  label,
  value,
  onChange,
  placeholder,
  minWidth = "200px",
  maxWidth = "300px",
}) => {
  return (
    <div style={{ flex: "1", minWidth, maxWidth }}>
      <label style={{ fontWeight: "600", marginBottom: "5px", display: "block" }}>
        {label}
      </label>
      <DatePicker
        selected={value}
        onChange={onChange}
        dateFormat="dd/MM/yyyy"
        className="form-control"
        maxDate={new Date()} 
        placeholderText={placeholder}
      />
    </div>
  );
};
