import React, { useContext, useState } from "react";
import CheckboxGroup from "./CheckboxGroup";
import { CommunicationContext } from "../CommunicationContext";
import moment from "moment";
import { sendSMS } from "../WATI API FUNCTIONS/sendSMS";

const CampaignDetailsForm = () => {
  const {
    title,
    setTitle,
    message,
    setMessage,
    selectedValue,
    setSelectedValue,
    data,
  } = useContext(CommunicationContext);

  // Validation states
  const [titleError, setTitleError] = useState(true);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    if (e.target.value.trim() !== "") setTitleError(false);
    else setTitleError(true);
  };

// const sendWhatsAppMessageTest = async () => {
//   // const whatsappNumber = "918178677009"
//   const whatsappNumber = "919001377465"
//   const templateName =  "rosemary"; 
//   const parameters = [
//     // {
//     //     name:  "customer",
//     //     value: "John Doe"
//     // },
//     // {
//     //     name: "WATI",
//     //     value: "WATI"
//     // }
// ];
//   const WATI_API_ENDPOINT = "https://live-mt-server.wati.io/12352";
// const API_KEY = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwODc1ZTczNC1kZTE4LTRlOTAtODVhOC01OGYzYmI1ZTQxMmIiLCJ1bmlxdWVfbmFtZSI6ImluZm9Ad2h5dGVmYXJtcy5jb20iLCJuYW1laWQiOiJpbmZvQHdoeXRlZmFybXMuY29tIiwiZW1haWwiOiJpbmZvQHdoeXRlZmFybXMuY29tIiwiYXV0aF90aW1lIjoiMDIvMjEvMjAyNSAwNDo0ODozNyIsInRlbmFudF9pZCI6IjEyMzUyIiwiZGJfbmFtZSI6Im10LXByb2QtVGVuYW50cyIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkFETUlOSVNUUkFUT1IiLCJleHAiOjI1MzQwMjMwMDgwMCwiaXNzIjoiQ2xhcmVfQUkiLCJhdWQiOiJDbGFyZV9BSSJ9.4BQUdUaut5fxbGfDcLd1PPMptwWmqNCh3bxSx7bZF-Q"; 
// const textLocalApiKey = "SsSIU4tDHEo-DWKzpHRKAWG8AtBIe3cMmkksUGCfPH";
  
//   console.log("parameters :",  parameters);
//     const payload = {
//       template_name: templateName,  
//       broadcast_name: "Campaign1",  
//       parameters: parameters.map(param => ({ name: param.name, value: param.value })),
//       whatsappNumber,  
//     };
//     console.log("request body ", JSON.stringify(payload));
    
  
//     try {
//         const response = await fetch(`${WATI_API_ENDPOINT}/api/v1/sendTemplateMessage?whatsappNumber=${whatsappNumber}`, {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json-patch+json",
//                 "Authorization": API_KEY
//             },
//             body: JSON.stringify(payload)
//         });
//         console.log("response: ", response);
        
//         const data = await response.json();
//         console.log("Message sent successfully:", data);
//         return data;
//     } catch (error) {
//         console.error("Error sending WhatsApp message:", error);
//         return null;
//     }
//   };



  return (
    <div className="card-body bg-light rounded m-4">
      <div className="row mb-3">
        <div className="col-md-6">
          {/* Title Input */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <label className="card-title mb-0" style={{ minWidth: "70px" }}>
              Title<span style={{ color: "red" }}>*</span>
            </label>
            <div style={{ width: "100%" }}>
              <input
                className={`form-control ${titleError ? "border-danger" : ""}`}
                type="text"
                placeholder="Enter campaign title"
                id="title"
                name="title"
                value={title}
                onChange={handleTitleChange}
              />
              {titleError && <small className="text-danger">Title is required</small>}
            </div>
          </div>

          {/* Customer Found */}
          {/* <div className="d-flex justify-content-between align-items-center mb-3">
            <label className="card-title mb-0">Customer found<span style={{ color: "red" }}>*</span></label>
            <input
              className="form-control"
              style={{ maxWidth: "200px" }}
              type="text"
              value={data.length}
              readOnly
            />
          </div> */}

          {/* Date */}
          {/* <div className="d-flex justify-content-between align-items-center mb-3">
            <label className="card-title mb-0">Date<span style={{ color: "red" }}>*</span></label>
            <input
              className="form-control "
              style={{ maxWidth: "200px" }}
              type="text"
              value={moment(new Date()).format("DD-MM-YYYY")}
              readOnly
            />
          </div> */}
        </div>

        {/* Platform Medium */}
        <div className="col-md-6">
          <CheckboxGroup
            property={"Platform"}
            options={[
              { value: "SMS", label: "SMS", id: "sms" },
              { value: "WhatsApp", label: "WhatsApp", id: "whatsapp" },
              // { value: "Email", label: "Email", id: "email" },
            ]}
            selectedValues={selectedValue}

            
            onChange={setSelectedValue}
          />
          {selectedValue.length===0 && <small className="text-danger">Select at least one platform</small>}
        </div>
      </div>

    </div>
  );
};

export default CampaignDetailsForm;
